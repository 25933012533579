import React from 'react';

import { withStyles, Typography, Button, Modal, Paper } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, sharedStyle);

function InfoModal({ classes, open, onClose, message, title }) {
    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.saveTemplateModal)}>
                <div className={classNames(classes.row, classes.justifyCenter, classes.itemSpacingContainer)}>
                    <Typography
                        className={classNames(classes.modalDescription, classes.alignCenter, classes.modalMargins)}
                        variant="h3"
                    >
                        {title}
                    </Typography>
                </div>
                <div className={classes.row}>
                    <Typography className={classNames(classes.modalDescription, classes.modalMargins)}>
                        {message}
                    </Typography>
                </div>
                <div className={classNames(classes.row, classes.justifyCenter, classes.itemSpacingContainer)}>
                    <Button
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
}

InfoModal.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default withStyles(style)(InfoModal);
