import React from 'react';

import { withStyles, Typography, Button, Modal, Paper } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, sharedStyle);

function DeleteWeekModal({ classes, deleteWeekData, setDeleteWeekData, deleteWeek }) {
    const onClose = () => setDeleteWeekData(data => ({ ...data, open: false }));

    const { open } = deleteWeekData;

    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.saveTemplateModal)}>
                <div className={classNames(classes.row, classes.justifyCenter)}>
                    <Typography className={classes.modalTitle}>Delete week:</Typography>
                </div>
                <div className={classes.row}>
                    <Typography className={classes.modalDescription}>
                        Are you sure you want to delete the week data?
                    </Typography>
                </div>
                <div
                    className={classNames(
                        classes.row,
                        classes.justifyCenter,
                        classes.itemSpacingContainer,
                        classes.topSpacing
                    )}
                >
                    <Button
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classNames(classes.button, classes.confirmButton)}
                        variant="contained"
                        onClick={deleteWeek}
                    >
                        Delete
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
}

DeleteWeekModal.propTypes = {
    classes: PropTypes.object.isRequired,
    deleteWeekData: PropTypes.object.isRequired,
    setDeleteWeekData: PropTypes.func.isRequired,
    deleteWeek: PropTypes.func.isRequired
};

export default withStyles(style)(DeleteWeekModal);
