import * as actions from './actionTypes';

import practitionerApi from '../api/practitionerApi';

export const getPractitioners = () => {
    return dispatch => {
        practitionerApi.query().then(result =>
            dispatch({
                type: actions.FETCHED_PRACTITIONER,
                payload: result
            })
        );
    };
};
