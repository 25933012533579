import * as actions from '../actions/actionTypes';

const defaultState = {
    clinics: []
};

const clinicReducer = (state = defaultState, action) => {
    switch (action.type) {
        case actions.FETCHED_CLINICS:
            return {
                ...state,
                clinics: action.payload
            };
        default:
            return state;
    }
};

export default clinicReducer;
