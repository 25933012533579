import React from 'react';

import { withStyles, Typography, Button, Modal, Paper } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DeleteLeaveButton from './deleteLeaveButton';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, sharedStyle);

function DeleteLeaveModal({ classes, leaveData, open, onClose, closeLeaveModal, reloadAllData = () => {} }) {
    const closeModals = () => {
        onClose();
        closeLeaveModal();
    };

    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.saveTemplateModal)}>
                <div className={classNames(classes.row, classes.justifyCenter)}>
                    <Typography className={classes.modalTitle}>Delete Leave</Typography>
                </div>
                <div className={classes.row}>
                    <Typography className={classes.modalDescription}>
                        This will permanently delete this leave.
                        <br />
                        Are you sure you want to continue?
                    </Typography>
                </div>
                <div className={classNames(classes.row, classes.justifyCenter, classes.itemSpacingContainer)}>
                    <Button
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <DeleteLeaveButton leaveData={leaveData} closeModals={closeModals} reloadAllData={reloadAllData} />
                </div>
            </Paper>
        </Modal>
    );
}

DeleteLeaveModal.propTypes = {
    classes: PropTypes.object.isRequired,
    leaveData: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    closeLeaveModal: PropTypes.func.isRequired,
    reloadAllData: PropTypes.func
};

export default withStyles(style)(DeleteLeaveModal);
