import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import Moment from 'moment';
import PropTypes from 'prop-types';
import MomentDurationFormat from 'moment-duration-format';
import { getShifts, getLeaves } from '../../services/resumedDataHelpers';

import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle);

MomentDurationFormat(Moment);

function WeekResume({ classes, practitionerSchedules, practitionerLeaves, startOfWeek }) {
    const weekSchedules = practitionerSchedules.flatMap(practitionerSchedule => practitionerSchedule.schedules);
    const weekLeaves = practitionerLeaves.flatMap(practitionerLeave => practitionerLeave.leaves);

    const week = Array(7)
        .fill(startOfWeek.clone())
        .map((e, i) => e.clone().add(i, 'days'));

    const leaves = day =>
        getLeaves(weekLeaves, day).map((leaveTime, index) => (
            <Typography className={classes.textAlignCenter} key={index}>
                {leaveTime}
            </Typography>
        ));
    const shifts = day => <Typography className={classes.textAlignCenter}>{getShifts(weekSchedules, day, weekLeaves)}</Typography>;

    return (
        <tr>
            <td>
                {leaves()}
                {shifts()}
            </td>
            {week.map((day, index) => (
                <td key={index}>
                    {leaves(day)}
                    {shifts(day)}
                </td>
            ))}
        </tr>
    );
}

WeekResume.propTypes = {
    classes: PropTypes.object.isRequired,
    practitionerSchedules: PropTypes.array.isRequired,
    practitionerLeaves: PropTypes.array.isRequired,
    startOfWeek: PropTypes.object.isRequired
};

export default withStyles(style)(WeekResume);
