import React from 'react';

import { withStyles, IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { Calendar } from '../../assets/icons';

import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle);

// eslint-disable-next-line no-unused-vars
function NavigationCalendarButton({ classes, onChange, value, variant }) {
    const [datePickerIsOpen, setDatePickerIsOpen] = React.useState(false);

    const openDatePicker = () => {
        setDatePickerIsOpen(true);
    };

    const closeDatePicker = () => {
        setDatePickerIsOpen(false);
    };

    return (
        <>
            <DatePicker
                style={{ display: 'none' }}
                autoOk
                disableToolbar
                value={value}
                onChange={onChange}
                open={datePickerIsOpen}
                onClose={closeDatePicker}
            />
            <IconButton onClick={openDatePicker}>
                <Calendar variant={variant} />
            </IconButton>
        </>
    );
}

NavigationCalendarButton.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    variant: PropTypes.string
};

export default withStyles(style)(NavigationCalendarButton);
