export const dateInputStyles = () => ({
    root: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    }
});

export const weekDayStyle = theme => ({
    weekDay: {
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
        flex: '1 1 0px',
        backgroundColor: theme.palette.gray.lighter
    },
    weekDayContent: {
        margin: theme.spacing(0, 1),
        borderCollapse: 'collapse',
        '& tr td': {
            border: '0px',
            verticalAlign: 'middle'
        },
        '& tr td:last-child': {
            textAlign: 'right',
            width: '100%',
            paddingLeft: theme.spacing(1)
        }
    },
    onlyTextSpacing: {
        paddingTop: 4.71,
        paddingBottom: 4.71
    },
    checkBoxField: {
        padding: '6.15px'
    },
    leave: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '3px',
        backgroundColor: '#f94940',
        border: '1px solid #f94940',
        color: '#fff', borderRadius: '3px',
        '& :first-child': {
            flexGrow: 3
        },
        '& :nth-child(2)': {
            textAlign: 'center',
            display: 'flex',
            flexGrow: 3,
            justifyContent: 'center',
            flexDirection: 'column',
            '& :nth-child(2)': {
                fontSize: 12
            }
        },
        '& :nth-child(3)': {
            flexGrow: 1
        }
    }
});

export const calendarHeaderStyle = theme => ({
    calendarHeader: {
        backgroundColor: theme.palette.gray.light,
        borderTop: '1px solid black',
        borderLeft: '1px solid black'
    }
});

export const practitionerCardStyle = theme => ({
    practitionerCard: {
        width: 162,
        border: '1px solid black',
        backgroundColor: theme.palette.gray.lighter
    },
    practitionerCardHeader: {
        backgroundColor: theme.palette.gray.light
    },
    practitionerCardBody: {
        padding: theme.spacing(1),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    practitionerCardContent: {
        padding: theme.spacing(0, 1)
    },
    practitionerCardLabel: {
        flex: '1 0 0px'
    },
    practitionerCardImage: {
        height: 50,
        width: 50
    }
});
