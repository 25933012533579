import React from 'react';

import { withStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle, weekDayCalendarStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import getWhiteOrBlackColor from '../../services/getWhiteOrBlackColor';
import LEAVE_TYPES from '../../constants/leaveTypes';
const style = mergeStyles(commonStyle, sharedStyle, weekDayCalendarStyle);

MomentDurationFormat(Moment);

function WeekDayCalendar({ practitioner, classes, day, schedule, leave, openDayPopup, openLeaveModal, showLocation }) {
    const history = useHistory();

    const goToEditWeekSchedule = () =>
        history.push({ pathname: '/schedule/edit', search: `?date=${day.format('YYYY-MM-DD')}` });

    const getEventTitle = event => {
        const startTime = event.start;
        const endTime = event.end;
        const start = Moment(startTime, 'HH:mm');
        const end = Moment(endTime, 'HH:mm');
        const duration = Moment.duration(end.diff(start, 'minutes'), 'minutes');
        const durationString = duration.format('HH:mm', { trim: false });
        const locationString = showLocation ? (event.clinic && event.clinic.accountName) || '' : '';
        const timesString = `${startTime}-${endTime} ${durationString}h`;
        return (
            <>
                <Typography className={classNames(classes.centerLabel, classes.textAlignCenter)}>
                    {locationString}
                </Typography>
                <Typography className={classNames(classes.centerLabel, classes.textAlignCenter)}>
                    {event.type ? 'Leave - ' + LEAVE_TYPES.find(lt => lt.value === event.type)?.label : ''}
                    {event.type && <br />}
                    {timesString}
                </Typography>
            </>
        );
    };

    const getEventStyle = practitioner => {
        const style = {
            backgroundColor: practitioner.colour || '#000000',
            border: practitioner.colour ? getWhiteOrBlackColor(practitioner.colour) : '#ffffff',
            color: practitioner.colour ? getWhiteOrBlackColor(practitioner.colour) : '#ffffff'
        };
        return style;
    };

    const getLeaveStyle = () => ({ backgroundColor: '#f94940', border: '1px solid #f94940', color: '#000000' });

    return (
        <td className={classNames(classes.weekDay)} onClick={goToEditWeekSchedule}>
            <div
                className={classNames(
                    classes.column,
                    classes.weekDayContent,
                    classes.grow,
                    classes.alignStretch,
                    classes.justifyCenter
                )}
            >
                {schedule.map((event, index) => (
                    <div
                        onClick={evt => openDayPopup(evt, day)}
                        style={getEventStyle(practitioner)}
                        className={classNames(
                            classes.column,
                            classes.justifyCenter,
                            classes.alignCenter,
                            classes.eventContainer
                        )}
                        key={index}
                    >
                        {getEventTitle(event)}
                    </div>
                ))}
                {leave.map((event, index) => (
                    <div
                        onClick={evt => openLeaveModal(evt, event.id)}
                        style={getLeaveStyle()}
                        className={classNames(
                            classes.column,
                            classes.justifyCenter,
                            classes.alignCenter,
                            classes.eventContainer
                        )}
                        key={index}
                    >
                        {getEventTitle(event)}
                    </div>
                ))}
            </div>
        </td>
    );
}

WeekDayCalendar.propTypes = {
    classes: PropTypes.object,
    practitioner: PropTypes.object,
    day: PropTypes.object,
    schedule: PropTypes.array,
    leave: PropTypes.array,
    openDayPopup: PropTypes.func,
    openLeaveModal: PropTypes.func,
    showLocation: PropTypes.bool.isRequired
};

export default withStyles(style)(WeekDayCalendar);
