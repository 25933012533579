import api from './api';

const query = () =>
    api
        .request({
            method: 'GET',
            url: '/practitioners/list-actives'
        })
        .then(res => {
            const currentClinic = localStorage.getItem('currentClinic');
            return res[res?.findIndex(clinic => clinic.id === currentClinic)]?.practitioners || [];
        });

const findExistingAppointments = (date, practitioner) => {
    return api.request({
        method: 'GET',
        url: `/appointments/count-appointments?practitioner=${practitioner}&day=${date}`
    });
};

export default {
    query,
    findExistingAppointments
};
