import React, { useState, useEffect } from 'react';

import { Typography, withStyles } from '@material-ui/core';
import Classnames from 'classnames';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import PropTypes from 'prop-types';
import PractitionerCard from '../common/practitionerCard';
import WeekDay from '../common/weekDay';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { editPractitionerWeekScheduleStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import Modal from '../../collums-components/components/common/Modal';
import practitionerLeaveApi from '../../api/practitionerLeaveApi';

const style = mergeStyles(commonStyle, editPractitionerWeekScheduleStyle);

MomentDurationFormat(Moment);

function EditPractitionerWeekSchedule({
    classes,
    practitionerForm,
    setPractitionerForm,
    practitionerIndex,
    clinics,
    currentClinicId,
    startOfWeek,
    schedulesAnotherLoc = []
}) {
    const [weekWithClinics, setWeekWithClinics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [overlapModalContent, setOverlapModalContent] = useState(null);
    const [deleteLeaveId, setDeleteLeaveId] = useState(null);

    const setDay = (dayIndex, newDay, showClashMessage = true) => {
        if (schedulesAnotherLoc.length && newDay.working) {
            if (newDay?.clinic && newDay.clinic !== currentClinicId) {
                const found = schedulesAnotherLoc.filter(item => item?.clinic?.[0]?.id === newDay.clinic);
                if (found.length && modalContent === null) {
                    setModalContent({ index: dayIndex, name: found?.[0]?.clinic?.[0]?.accountName, data: newDay });
                }
            }
            if (newDay?.clinic && newDay.clinic === currentClinicId) {
                const date = Moment(startOfWeek)
                    .add(dayIndex, 'days')
                    .format('YYYY-MM-DD');
                const found = schedulesAnotherLoc.filter(item => item?.date === date);
                if (found.length) {
                    const overlappingSchedule = checkOverlap(newDay, found);
                    if (overlappingSchedule) {
                        setOverlapModalContent({
                            date,
                            clinic: clinics.find(item => item.id === overlappingSchedule[2]),
                            dayIndex,
                            newDay,
                            autoclose: !showClashMessage
                        });
                        return false;
                    }
                }
            }
        }
        const newWeek = [...practitionerForm.week];
        let toUpdate = newWeek.map((element, index) => {
            if (dayIndex === index) return newDay;
            return element;
        });
        setWeekWithClinics(toUpdate);
        setPractitionerForm(practitionerIndex, toUpdate);
    };

    const checkOverlap = (current, schedules) => {
        const timeSegments = [...schedules.map(item => [item.start, item.end, item?.clinic?.[0]?.id])];
        timeSegments.push([current.start, current.end, current.clinic]);
        timeSegments.sort((timeSegment1, timeSegment2) => timeSegment1[0].localeCompare(timeSegment2[0]));
        for (let i = 0; i < timeSegments.length - 1; i++) {
            const currentEndTime = timeSegments[i][1];
            const nextStartTime = timeSegments[i + 1][0];

            if (currentEndTime > nextStartTime) {
                return timeSegments[i][2] === current.clinic ? timeSegments[i + 1] : timeSegments[i];
            }
        }

        return false;
    };

    const { week } = practitionerForm;
    const weekSchedules = week.filter(day => day.working);
    const weekTotalHours = weekSchedules
        .reduce(
            (duration, schedule) =>
                duration
                    .clone()
                    .add(Moment(schedule.end, 'HH:mm').diff(Moment(schedule.start, 'HH:mm'), 'minutes'), 'minutes'),
            Moment.duration(0)
        )
        .format('HH:mm', { trim: false });

    const closeModal = () => {
        setModalContent(false);
        setTimeout(() => {
            document.activeElement.blur();
        }, 100);
    };

    const closeDeleteLeaveModal = () => {
        setDeleteLeaveId(null);
        setTimeout(() => {
            document.activeElement.blur();
        }, 100);
    };

    const deleteLeave = async (id) => {
        await practitionerLeaveApi.remove(id);
        const newLeaves = practitionerForm.leaves.map(leaveDay => leaveDay.filter(l => l.id !== id));
        setPractitionerForm(practitionerIndex, null, newLeaves);
        setWeekWithClinics(weekWithClinics.map(day => ({ ...day, leaves: day.leaves.filter(l => l.id !== id) })));
    };

    const closeOverlapModal = () => {
        // eslint-disable-next-line no-unused-vars
        const { clinic, ...data } = overlapModalContent.newDay;
        setDay(overlapModalContent.dayIndex, { ...data, clinic: '' });
        setOverlapModalContent(null);
        setTimeout(() => {
            document.activeElement.blur();
        }, 100);
    };

    useEffect(() => {
        setIsLoading(true);
        if (clinics.length > 0) {
            const _week = practitionerForm.week.map((day, index) => {
                day.leaves = practitionerForm.leaves[index] ? practitionerForm.leaves[index] : [];
                if (!day.working) return day;
                return {
                    ...day,
                    clinic: day.clinic || undefined
                };
            });
            setWeekWithClinics(_week);
        }
        setIsLoading(false);
        /*eslint-disable-next-line */
    }, [practitionerForm.week]);

    return (
        <>
            {isLoading && <LoadingScreen />}
            <tr className={Classnames(classes.week, classes.practitionerWeekSchedule)}>
                <PractitionerCard
                    practitioner={practitionerForm.practitioner}
                    shifts={weekSchedules.length}
                    totalHours={weekTotalHours}
                />
                {weekWithClinics.map((day, dayIndex) => (
                    <WeekDay
                        day={day}
                        setDay={setDay}
                        setDeleteLeaveId={setDeleteLeaveId}
                        weekIndex={0}
                        dayIndex={dayIndex}
                        key={dayIndex}
                        practitioner={practitionerForm.practitioner}
                        clinics={clinics}
                    />
                ))}
            </tr>
            {modalContent && (
                <CancelContinueModal
                    title="Schedule update"
                    contentText={`There is an existing schedule in ${modalContent.name} for this staff member. This will be updated when you click save.`}
                    cancelButtonText="Back"
                    continueButtonText="Continue"
                    setOpen={() => setModalContent(false)}
                    onCancel={() => {
                        // eslint-disable-next-line no-unused-vars
                        const { clinic, ...data } = modalContent.data;
                        setDay(modalContent.index, data);
                        closeModal();
                    }}
                    onContinue={() => {
                        setModalContent(false);
                        closeModal();
                    }}
                />
            )}
            {overlapModalContent && (
                <Modal
                    id="overlap-modal"
                    isOpen
                    title="Schedule clash!"
                    titleStyle={{
                        fontWeight: 500,
                        fontSize: 18
                    }}
                    onCancel={closeOverlapModal}
                    onClose={closeOverlapModal}
                    onConfirm={closeOverlapModal}
                    hideCancel
                    size="sm"
                >
                    <Typography variant="h4">
                        This staff member is already working at {overlapModalContent.clinic?.accountName} at this time
                        on {overlapModalContent.date}. Please change this schedule before saving.
                    </Typography>
                </Modal>
            )}
            {overlapModalContent && overlapModalContent.autoclose && closeOverlapModal()}
            {deleteLeaveId && (
                <CancelContinueModal
                    title="Leave remove"
                    contentText={'Are you sure you want to delete Leave?'}
                    cancelButtonText="Back"
                    continueButtonText="Yes"
                    onCancel={() => {
                        closeDeleteLeaveModal();
                    }}
                    onContinue={() => {
                        deleteLeave(deleteLeaveId);
                        closeDeleteLeaveModal();
                    }}
                />
            )}
        </>
    );
}

EditPractitionerWeekSchedule.propTypes = {
    classes: PropTypes.object.isRequired,
    clinics: PropTypes.object.isRequired,
    practitionerForm: PropTypes.object.isRequired,
    setPractitionerForm: PropTypes.func.isRequired,
    practitionerIndex: PropTypes.number.isRequired,
    currentClinicId: PropTypes.string,
    schedulesAnotherLoc: PropTypes.array,
    startOfWeek: PropTypes.object
};

export default withStyles(style)(EditPractitionerWeekSchedule);
