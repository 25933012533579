import * as actions from './actionTypes';

import practitionerScheduleApi from '../api/practitionerScheduleApi';
import scheduleTemplateApi from '../api/scheduleTemplateApi';

export const createPratictionerSchedule = (values, postAction) => {
    return dispatch => {
        practitionerScheduleApi.create(values).then(() => {
            if (postAction) postAction();

            return dispatch({
                type: actions.CREATED_PRACTITIONER_SCHEDULE
            });
        });
    };
};

export const createScheduleTemplate = values => {
    return dispatch => {
        scheduleTemplateApi.create(values).then(() => {
            dispatch(getTemplates());
        });
    };
};

export const getTemplates = () => {
    return dispatch => {
        scheduleTemplateApi.query().then(result => {
            return dispatch({
                type: actions.FETCHED_TEMPLATE_SCHEDULE,
                payload: result
            });
        });
    };
};

export const deleteTemplate = (values, postAction) => {
    return dispatch => {
        scheduleTemplateApi.removeMany(values).then(() => {
            if (postAction) postAction();

            return dispatch(getTemplates());
        });
    };
};
