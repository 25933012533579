import React, { useState } from 'react';

import { Button, withStyles, Typography } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import practitionerScheduleApi from '../../api/practitionerScheduleApi';

import { commonStyle, mergeStyles } from '../../style/common';
import { sharedStyle } from './style';
import Modal from '../../collums-components/components/common/Modal';
import LoadingScreen from './../../collums-components/components/common/loadingScreen';
const style = mergeStyles(commonStyle, sharedStyle);

// eslint-disable-next-line no-unused-vars
const EditScheduleButton = ({ form, initialForm, startOfWeek, onSuccess, classes, currentClinicId = null }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [schedulesOnWarning, setSchedulesOnWarning] = useState([]);

    const saveSchedule = async () => {
        try {
            const hasClinics = form
                .flatMap(item => item.week)
                .filter(item => item.working)
                .every(item => !!item.clinic);

            if (!hasClinics) {
                toastr.error('Please set clinic for each schedule');
                return false;
            }

            setIsLoading(true);
            await practitionerScheduleApi.update(
                form.flatMap(item =>
                    item.week
                        .map((schedule, index) => {
                            const scheduleData = {
                                clinic: schedule.clinic,
                                start: schedule.start,
                                end: schedule.end
                            };
                            return {
                                id: schedule.id,
                                working: schedule.working,
                                day: startOfWeek
                                    .clone()
                                    .add(index, 'days')
                                    .format('YYYY-MM-DD'),
                                practitioner: item.practitioner.id,
                                ...(schedule.working ? scheduleData : {})
                            };
                        })
                        .filter(schedule => schedule.id || schedule.working)
                )
            );
            toastr.success('Schedule updated');
            const updatedSchedulesWithAppointment = [];
            initialForm.current.forEach((initialFormItem, formIndex) => {
                initialFormItem.week.forEach((oldData, weekIndex) => {
                    if (!oldData.appointmentsCount) {
                        return;
                    }
                    const newData = form[formIndex]['week'][weekIndex];
                    if (!isEqual(oldData, newData)) {
                        updatedSchedulesWithAppointment.push({
                            practitionerName: initialFormItem.practitioner.displayName,
                            day: startOfWeek
                                .clone()
                                .add(weekIndex, 'days')
                                .format('DD/MM/YYYY'),
                            id: `${initialFormItem.id}-${formIndex}-${weekIndex}`
                        });
                    }
                });
            });
            setIsLoading(false);
            if (updatedSchedulesWithAppointment.length) {
                setSchedulesOnWarning(updatedSchedulesWithAppointment);
                return;
            }
            await onSuccess();
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong');
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setSchedulesOnWarning([]);
        onSuccess();
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <Button
                className={classNames(classes.button, classes.saveButton)}
                variant="contained"
                onClick={saveSchedule}
                disabled={isLoading}
            >
                Save
            </Button>
            {schedulesOnWarning.length !== 0 && (
                <Modal
                    id="conflict-modal"
                    isOpen
                    title="Conflicts!"
                    titleStyle={{
                        fontWeight: 500,
                        fontSize: 18
                    }}
                    onCancel={closeModal}
                    onClose={closeModal}
                    onConfirm={closeModal}
                    hideCancel
                    size="sm"
                >
                    <Typography variant="h4">Schedules with appointments:</Typography>
                    <ul>
                        {schedulesOnWarning.map(schedule => {
                            return (
                                <li key={schedule.id}>
                                    <Typography>
                                        {schedule.practitionerName} {schedule.day}
                                    </Typography>
                                </li>
                            );
                        })}
                    </ul>
                </Modal>
            )}
        </>
    );
};

EditScheduleButton.propTypes = {
    form: PropTypes.array.isRequired,
    startOfWeek: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    initialForm: PropTypes.object,
    classes: PropTypes.object.isRequired,
    currentClinicId: PropTypes.string
};

export default withStyles(style)(EditScheduleButton);
