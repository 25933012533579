import AuthApi from '../api/AuthApi';
import { listClinics } from '../api/ClinicApi';

const CURRENT_CLINIC = 'currentClinic';

const getAllowedClinics = (user, clinics) => {
    if (!user || !clinics?.length) return [];
    return clinics.filter(c => (user.data || user)?.locations?.some(loc => loc.clinic === c.id));
};

/**
 * Special permissions for example to the amex disabled/enable payment option.
 */
export const getHasAdvancedPermissions = user => {
    if (!user?.email) {
        return false;
    }

    const domainsAccess = ['collums.co.uk', 'collums.co', 'sensilabs.pl'];

    return domainsAccess.some(domain => (user?.email?.address || '').lastIndexOf(`@${domain}`) !== -1);
};

export const isClinicValid = async (clinic, user) => {
    if (!user) {
        user = await AuthApi.getMe();
    }

    if (!user) return false;

    // all clinics
    const clinics = await listClinics();

    // clinics allowed by practitioner
    let allowedClinics = getAllowedClinics(user, clinics);

    if (!allowedClinics?.length) {
        return false;
    }

    return !!allowedClinics.find(allowedClinic => allowedClinic.id === clinic);
};

export const validateAndUpdateClinic = async (user, clinic) => {
    if (!user) {
        user = await AuthApi.getMe();
    }

    if (!user) return;

    // current selected clinic
    const currentClinic = clinic || localStorage.getItem(CURRENT_CLINIC);

    // all clinics
    const clinics = await listClinics();

    // clinics allowed by practitioner
    let allowedClinics = getAllowedClinics(user, clinics);

    if (!currentClinic) {
        // set allowed clinic if does not exists
        if (allowedClinics?.length) {
            localStorage.setItem(CURRENT_CLINIC, allowedClinics[0].id);
        } else {
            localStorage.setItem(CURRENT_CLINIC, clinics.data[0].id);
        }
    } else if (allowedClinics?.length) {
        // update clinic if not allowed
        const isClinicAllowed = allowedClinics.find(clinic => clinic.id === currentClinic);
        if (!isClinicAllowed) {
            localStorage.setItem(CURRENT_CLINIC, allowedClinics[0].id);
        } else {
            localStorage.setItem(CURRENT_CLINIC, currentClinic);
        }
    } else {
        localStorage.setItem(CURRENT_CLINIC, currentClinic);
    }
};
