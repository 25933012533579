import React, { useEffect, useState } from 'react';
import {
    withStyles,
    Typography,
    TextField,
    Button,
    Checkbox,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Radio,
    Chip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment-timezone';
import classNames from 'classnames';
import Week from './week';
import CreateScheduleButton from './createScheduleButton';
import SelectPractitionerInput from '../common/selectPractitionerInput';
import SelectTemplateInput from './selectTemplateInput';
import CopyWeekModal from './copyWeekModal';
import DeleteWeekModal from './deleteWeekModal';
import CopyDayModal from './copyDayModal';
import SaveTemplateModal from './saveTemplateModal';
import ManageTemplateModal from './manageTemplateModal';
import DateInput from '../common/dateInput';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import defaultWeekTemplate from '../../constants/defaultWeekTemplate';

import { createScheduleTemplate, getTemplates } from '../../actions/scheduleActions';

import { sharedStyle, newScheduleStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import { useDispatch, useSelector } from 'react-redux';
import JobApi from '../../collums-components/api/jobApi';
import { getClinicsAction } from '../../actions/clinicActions';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
import Modal from '../../collums-components/components/common/Modal';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';

const style = mergeStyles(commonStyle, sharedStyle, newScheduleStyle);

const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];
const endAfterOptions = Array(49)
    .fill(2)
    .map((e, i) => String(e + i));

const getValueIfEvent = item => (item.target ? item.target.value : item);

function NewSchedule({ classes }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentClinicName, setCurrentClinicName] = useState();
    const clinics = useSelector(store => store.clinic.clinics);
    // template select
    const templates = useSelector(state => state.schedule.templates);
    const practitioners = useSelector(state => state.practitioner.practitioners);
    const [optionsJobs, setOptionsJobs] = useState([]);
    const currentClinicId = localStorage.getItem(CURRENT_CLINIC);
    const [modalContent, setModalContent] = useState(null);
    const [overlapModalContent, setOverlapModalContent] = useState(null);
    const [modalContentAction, setModalContentAction] = useState({ func: () => {} });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (clinics?.length) {
            const findClinic = clinics.find(item => item.id === currentClinicId);
            setCurrentClinicName(findClinic ? findClinic.accountName : '');
        }
    }, [clinics, currentClinicId]);

    useEffect(() => {
        dispatch(getClinicsAction());
        dispatch(getTemplates());
    }, [dispatch]);

    useEffect(() => {
        const fetchFormData = async () => {
            const jobs = await JobApi.listAll();
            setOptionsJobs(jobs);
        };
        fetchFormData();
    }, []);

    const [form, setForm] = useState({
        practitioners: [],
        jobRoles: [],
        tags: [],
        startDate: Moment().format('YYYY-MM-DD'),
        recurring: false,
        endType: 'by', // or 'after'
        endDate: Moment()
            .add(7 * 4 - 1, 'days')
            .endOf('isoWeek')
            .format('YYYY-MM-DD'),
        endAfter: '2',
        weeks: [[...defaultWeekTemplate]]
    });
    const [copyWeekData, setCopyWeekData] = useState({
        open: false,
        weekIndexFrom: null,
        weekIndexTo: Array(4).fill(true)
    });
    const [deleteWeekData, setDeleteWeekData] = useState({
        open: false,
        weekIndexFrom: null
    });
    const [copyDayData, setCopyDayData] = useState({
        open: false,
        weekIndexFrom: 0,
        dayIndexFrom: null,
        dayIndexTo: [false, false, false, false, false, false, false]
    });
    const [saveTemplateData, setSaveTemplateData] = useState({
        open: false,
        templateName: ''
    });
    const [manageTemplate, setManageTemplate] = useState(false);

    const setJobRoles = (evt, jobRoles) => {
        const practitionersFilter = practitioners.filter(practitioner => {
            return jobRoles.some(job => job.id === practitioner.job);
        });
        setForm(form => ({ ...form, practitioners: practitionersFilter }));

        //setForm(form => ({ ...form, jobRoles }));
    };
    const setTags = (evt, tags) => {
        setForm(form => ({ ...form, tags }));
    };
    const setWeek = (weekIndex, newWeek) => {
        const newWeeks = [...form.weeks];
        newWeeks.splice(weekIndex, 1, newWeek);
        setForm(form => ({ ...form, weeks: newWeeks }));
    };
    const startDateChange = startDate => {
        let newStartDate = getValueIfEvent(startDate);
        setForm(form => ({ ...form, startDate: newStartDate }));
    };
    const setRecurring = recurring => {
        recurring = recurring.target.checked;
        setForm(form => ({ ...form, recurring }));
    };
    const setEndType = endType => {
        endType = getValueIfEvent(endType);
        setForm(form => ({ ...form, endType }));
    };
    const endDateChange = endDate => {
        let newEndDate = getValueIfEvent(endDate);
        if (newEndDate) {
            setForm(form => ({ ...form, endDate: newEndDate }));
        }
    };
    const setEndAfter = (evt, endAfter) => {
        setForm(form => ({ ...form, endAfter }));
    };

    const createWeek = weekIndexFrom => {
        if (form.weeks.length < 4) {
            const weeks = form.weeks;
            weeks.splice(weekIndexFrom + 1, 0, [...defaultWeekTemplate]);
            setForm({ ...form, weeks });
        }
    };
    const copyWeek = () => {
        const { weekIndexFrom } = copyWeekData;

        // Create the week if it's incomplete
        if (form.weeks.length !== 4)
            for (let i = form.weeks.length; i <= 4; i++) copyWeekData.weekIndexTo.map((el, index) => createWeek(index));

        // Fill with a copy of the first one, or with the default values (already assigned)
        const weeks = copyWeekData.weekIndexTo.map((el, index) => (el ? form.weeks[weekIndexFrom] : form.weeks[index]));

        setForm({ ...form, weeks });
        setCopyWeekData(data => ({ ...data, open: false }));
    };
    const deleteWeek = () => {
        const { weekIndexFrom } = deleteWeekData;
        const weeks = [...form.weeks];
        weeks.splice(weekIndexFrom, 1);
        setForm({ ...form, weeks });
        setDeleteWeekData(data => ({ ...data, open: false }));
    };
    const copyDay = () => {
        const { weekIndexFrom, dayIndexFrom, dayIndexTo } = copyDayData;
        const day = { ...form.weeks[weekIndexFrom][dayIndexFrom] };
        const week = [...form.weeks[weekIndexFrom]];
        dayIndexTo.forEach((e, i) => {
            e && week.splice(i, 1, day);
        });
        const weeks = [...form.weeks];
        weeks.splice(weekIndexFrom, 1, week);
        setForm(form => ({ ...form, weeks }));
        setCopyDayData(data => ({ ...data, open: false }));
    };
    const saveTemplate = () => {
        if (templates.map(template => template.name).includes(saveTemplateData.templateName)) {
            toastr.warning('Template name already exists.');
        } else {
            dispatch(
                createScheduleTemplate({
                    name: saveTemplateData.templateName,
                    weeks: form.weeks
                })
            );
            setSaveTemplateData({ templateName: '', open: false });
        }
    };
    const openCopyWeekModal = weekIndexFrom => {
        setCopyWeekData(data => ({
            ...data,
            open: true,
            weekIndexFrom
        }));
    };
    const openDeleteWeekModal = weekIndexFrom => {
        if (form.weeks.length > 1) {
            setDeleteWeekData(data => ({
                ...data,
                open: true,
                weekIndexFrom
            }));
        } else {
            toastr.warning('Cannot delete with only one week');
        }
    };
    const openCopyDayModal = (weekIndexFrom, dayIndexFrom) => {
        setCopyDayData(data => ({
            ...data,
            open: true,
            weekIndexFrom,
            dayIndexFrom,
            dayIndexTo: Array(7)
                .fill(false)
                .map((e, i) => i === dayIndexFrom)
        }));
    };
    const openSaveTemplateModal = () => {
        setSaveTemplateData(data => ({ ...data, open: true }));
    };
    const goToViewSchedule = () => {
        history.push('/schedule');
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <div className={classes.container}>
                <Typography className={classes.title} variant="h4">
                    {currentClinicName ? `${currentClinicName} - ` : ''}New Staff Schedules
                </Typography>
                {/* Autocomplete labels */}
                <div className={classes.row}>
                    <Typography>Apply schedule to:</Typography>
                </div>
                {/* Autocompletes */}
                <div className={classNames(classes.row, classes.itemSpacingContainer, classes.alignEnd)}>
                    <SelectPractitionerInput
                        multiple
                        className={classes.inputSpacing}
                        onSelect={practitioners => setForm({ ...form, practitioners })}
                        value={form.practitioners}
                    />
                    <Autocomplete
                        className={classes.inputSpacing}
                        fullWidth
                        autoComplete
                        autoHighlight
                        limitTags={1}
                        multiple
                        filterSelectedOptions
                        options={optionsJobs}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    key={option.id}
                                    label={<Typography style={{ fontSize: 14 }}>{option.name}</Typography>}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={option => option.name}
                        onChange={setJobRoles}
                        renderInput={params => <TextField {...params} variant="outlined" label="Job Role" />}
                    />
                    <Autocomplete
                        style={{ width: '95%' }}
                        fullWidth
                        autoComplete
                        autoHighlight
                        multiple
                        filterSelectedOptions
                        options={options}
                        value={form.tags}
                        onChange={setTags}
                        renderInput={params => <TextField {...params} variant="outlined" label="Tags" />}
                    />
                </div>
                <div style={{ marginTop: '0.5rem' }} className={classNames(classes.row, classes.itemSpacingContainer)}>
                    <SelectTemplateInput
                        options={templates}
                        onSelect={template => setForm({ ...form, weeks: [...template.weeks] })}
                    />

                    <div className={classNames(classes.grow)} />
                </div>
                {form.weeks.map((week, weekIndex) => (
                    <Week
                        week={week}
                        setWeek={setWeek}
                        weekIndex={weekIndex}
                        createWeek={createWeek}
                        openCopyWeekModal={openCopyWeekModal}
                        openDeleteWeekModal={openDeleteWeekModal}
                        openCopyDayModal={openCopyDayModal}
                        key={weekIndex}
                        clinics={clinics}
                    />
                ))}
                {/* Save button */}
                <div className={classNames(classes.row, classes.itemSpacingContainer)}>
                    <Button
                        className={classNames(classes.button, classes.openButton)}
                        variant="contained"
                        color="primary"
                        onClick={openSaveTemplateModal}
                    >
                        Save template
                    </Button>

                    <Button
                        className={classNames(classes.button, classes.openButton)}
                        variant="contained"
                        color="primary"
                        onClick={() => setManageTemplate(!manageTemplate)}
                    >
                        Manage templates
                    </Button>
                </div>
                {/* Schedule start date */}
                <div
                    className={classNames(
                        classes.row,
                        classes.alignCenter,
                        classes.itemSpacingContainer,
                        classes.topSpacing
                    )}
                >
                    <Typography className={classes.itemSpacing}>Schedule start date:</Typography>
                    <DateInput value={form.startDate} onChange={startDateChange} />
                </div>
                {/* recurring schedule */}
                <div className={classNames(classes.row, classes.alignCenter)}>
                    <Checkbox size="small" value={form.recurring} onChange={setRecurring} />
                    <Typography>Recurring schedule</Typography>
                </div>
                {/* ONLY SHOW IF RECURRING IS SELECTED */}
                {/* Schedule end date */}
                {form.recurring && (
                    <FormControl component="div" className={classNames(classes.row, classes.itemSpacingContainer)}>
                        <Typography className={classNames(classes.endLabel)}>Schedule end date:</Typography>
                        <RadioGroup
                            className={classNames(classes.column)}
                            value={form.endType}
                            onChange={setEndType}
                            aria-label="end-type"
                            name="endType"
                        >
                            <div className={classNames(classes.row, classes.endDateSection)}>
                                <FormControlLabel value="by" control={<Radio size="small" />} label="By" />
                                <DateInput
                                    value={form.endDate}
                                    onChange={endDateChange}
                                    disabled={form.endType !== 'by'}
                                />
                            </div>
                            <div className={classNames(classes.row, classes.alignCenter, classes.occurrencesSection)}>
                                <FormControlLabel value="after" control={<Radio size="small" />} label="After" />
                                <Autocomplete
                                    autoComplete
                                    autoHighlight
                                    options={endAfterOptions}
                                    value={form.endAfter}
                                    onChange={setEndAfter}
                                    disabled={form.endType !== 'after'}
                                    renderInput={params => <TextField variant="outlined" {...params} />}
                                />
                                <Typography>occurrences</Typography>
                            </div>
                        </RadioGroup>
                    </FormControl>
                )}
                {/* Cancel and save buttons */}
                <div className={classNames(classes.row, classes.itemSpacingContainer, classes.bottomButtons)}>
                    <Button
                        onClick={goToViewSchedule}
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <CreateScheduleButton form={form} setModalContent={setModalContent} setOverlapModalContent={setOverlapModalContent} setModalContentAction={setModalContentAction} setIsLoading={setIsLoading} />
                </div>
            </div>
            {modalContent && (
                <CancelContinueModal
                    title="Schedule update"
                    contentHtml={`<div style="text-align: start">There are an existing schedules:<br /> <ul><li>${modalContent.join('</li><li>')}</li></ul>.<br />This will be updated when you click save.</div>`}
                    cancelButtonText="Back"
                    continueButtonText="Save"
                    setOpen={() => setModalContent(null)}
                    onCancel={() => setModalContent(null)}
                    onContinue={() => {
                        setModalContent(null);
                        modalContentAction.func(false);
                    }}
                />
            )}
            {overlapModalContent && (
                <Modal
                    id="overlap-modal"
                    isOpen
                    title="Schedule clash!"
                    titleStyle={{
                        fontWeight: 500,
                        fontSize: 18
                    }}
                    onCancel={() => setOverlapModalContent(null)}
                    onClose={() => setOverlapModalContent(null)}
                    onConfirm={() => setOverlapModalContent(null)}
                    hideCancel
                    size="sm"
                >
                    <Typography variant="h4">
                        There are existing schedules:
                        <ul>
                            {overlapModalContent.map((conflict, index) => (<li key={index}>{conflict}</li>))}
                        </ul>
                        Please change these schedules before saving.
                    </Typography>
                </Modal>
            )}
            <CopyWeekModal
                copyWeekData={copyWeekData}
                setCopyWeekData={setCopyWeekData}
                copyWeek={copyWeek}
                form={form}
            />
            <DeleteWeekModal
                deleteWeekData={deleteWeekData}
                setDeleteWeekData={setDeleteWeekData}
                deleteWeek={deleteWeek}
                form={form}
            />
            <CopyDayModal copyDayData={copyDayData} setCopyDayData={setCopyDayData} copyDay={copyDay} form={form} />
            <SaveTemplateModal
                saveTemplateData={saveTemplateData}
                setSaveTemplateData={setSaveTemplateData}
                saveTemplate={saveTemplate}
            />
            <ManageTemplateModal manageTemplateData={manageTemplate} setManageTemplateData={setManageTemplate} />
        </>
    );
}

NewSchedule.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(NewSchedule);
