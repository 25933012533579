import React from 'react';

import { withStyles, Typography, IconButton } from '@material-ui/core';
import Classnames from 'classnames';
import WeekDay from '../common/weekDay';
import { Add, Copy, Trash } from '../../assets/icons';
import Moment from 'moment';
import moment from 'moment-timezone';
import MomentDurationFormat from 'moment-duration-format';
import PropTypes from 'prop-types';
import CalendarHeader from '../common/calendarHeader';

import { weekStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, weekStyle);

MomentDurationFormat(Moment);

function Week({
    classes,
    week,
    setWeek,
    weekIndex,
    createWeek,
    openCopyWeekModal,
    openDeleteWeekModal,
    openCopyDayModal,
    clinics
}) {
    const setDay = (dayIndex, newDay) => {
        const newWeek = [...week];
        newWeek.splice(dayIndex, 1, newDay);
        setWeek(weekIndex, newWeek);
    };

    const getWorkingDays = () => week.filter(e => e.working);
    const getShifts = () => getWorkingDays().length;
    const getDayDurations = () =>
        getWorkingDays().map(e => Moment.utc(e.end, 'HH:mm').diff(Moment.utc(e.start, 'HH:mm'), 'minutes'));
    const getWeekHours = () =>
        getDayDurations()
            .reduce((duration, mins) => duration.clone().add(mins, 'minutes'), Moment.duration(0, 'minutes'))
            .format('HH:mm', { trim: false });

    const cells = Array(7)
        .fill(1)
        .map((e, i) => ({
            content: moment()
                .startOf('isoWeek')
                .add(i, 'days')
                .format('dddd'),
            style: { textAlign: 'center' }
        }));

    return (
        <div className={Classnames(classes.column, classes.topSpacing)}>
            {/* Week label */}
            <div className={classes.row}>
                <Typography>{`Week ${weekIndex + 1}`}</Typography>
            </div>
            {/* Week thingy */}
            <table
                className={Classnames(classes.calendarTable, classes.newWeekSchedule)}
                style={{ borderBottom: (openCopyWeekModal ? '1px' : '0px') + ' solid black' }}
            >
                <CalendarHeader cells={cells} />
                <tbody>
                    <tr>
                        {week.map((day, dayIndex) => (
                            <WeekDay
                                day={day}
                                setDay={setDay}
                                weekIndex={weekIndex}
                                dayIndex={dayIndex}
                                openCopyDayModal={openCopyDayModal}
                                key={dayIndex}
                                showCopyDay
                                clinics={clinics}
                            />
                        ))}
                    </tr>
                </tbody>
            </table>
            {/* Week details and buttons */}
            <div className={Classnames(classes.row, classes.spaceBetween, classes.alignCenter)}>
                <Typography>{`${getShifts()} shifts / ${getWeekHours()}hrs`}</Typography>
                <div className={classes.row}>
                    <IconButton onClick={() => createWeek(weekIndex)}>
                        <Add />
                    </IconButton>
                    <IconButton onClick={() => openCopyWeekModal(weekIndex)}>
                        <Copy />
                    </IconButton>
                    <IconButton onClick={() => openDeleteWeekModal(weekIndex)}>
                        <Trash />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

Week.propTypes = {
    classes: PropTypes.object.isRequired,
    week: PropTypes.array.isRequired,
    clinics: PropTypes.object.isRequired,
    setWeek: PropTypes.func.isRequired,
    createWeek: PropTypes.func.isRequired,
    openCopyWeekModal: PropTypes.func.isRequired,
    openDeleteWeekModal: PropTypes.func.isRequired,
    openCopyDayModal: PropTypes.func.isRequired,
    weekIndex: PropTypes.number.isRequired
};

export default withStyles(style)(Week);
