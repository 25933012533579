import * as actions from '../actions/actionTypes';

import SCHEDULE_VIEW_MODES from '../constants/scheduleViewModes';

const defaultStore = {
    viewMode: SCHEDULE_VIEW_MODES.month
};

const utilsReducer = (store = defaultStore, action) => {
    switch (action.type) {
        case actions.VIEW_MODE:
            return {
                ...store,
                viewMode: action.payload
            };

        default:
            return store;
    }
};

export default utilsReducer;
