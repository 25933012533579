const LEAVE_TYPES = [
    { label: 'Annual', value: 'ANNUAL' },
    { label: 'Sickness', value: 'SICKNESS' },
    { label: 'Compassionate', value: 'COMPASSIONATE' },
    { label: 'Maternity', value: 'MATERNITY' },
    { label: 'Paternity', value: 'PATERNITY' },
    { label: 'Shared parental', value: 'SHARED_PARENTAL' }
];

export default LEAVE_TYPES;
