import React, { useEffect, useState } from 'react';

import { withStyles, Typography, TextField, IconButton, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import MonthView from './monthView';
import YearView from './yearView';
import WeekView from './weekView';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DayPopupModal from './dayPopupModal';
import NewLeaveModal from './newLeaveModal';
import SelectPractitionerInput from '../common/selectPractitionerInput';
import practitionerScheduleApi from '../../api/practitionerScheduleApi';
import practitionerLeaveApi from '../../api/practitionerLeaveApi';
import { getLeaves, getShifts } from '../../services/resumedDataHelpers';
import {
    CalendarMonth,
    CalendarMonthSelected,
    CalendarWeek,
    CalendarWeekSelected,
    CalendarYear,
    CalendarYearSelected
} from '../../assets/icons';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import { useSelector, useDispatch } from 'react-redux';
import SCHEDULE_VIEW_MODES from '../../constants/scheduleViewModes';

import { getClinicsAction } from '../../actions/clinicActions';
import { changeViewMode } from '../../actions/utilsActions';
import JobApi from '../../collums-components/api/jobApi';
import ClinicAvailability from '../../collums-components/components/common/ShowClinicAvailability';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import AuthApi from '../../collums-components/api/AuthApi';

const style = mergeStyles(commonStyle, sharedStyle);

const OPTIONS = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];

function ViewSchedule({ classes }) {
    const history = useHistory();

    const dispatch = useDispatch();

    const viewMode = useSelector(store => store.utils.viewMode);

    const clinics = useSelector(store => store.clinic.clinics);

    const [dayPopupData, setDayPopupData] = useState({
        open: false,
        events: [],
        date: moment()
    });
    const [leaveData, setLeaveData] = useState({
        open: false,
        practitioner: null,
        type: null,
        clinic: '',
        paid: true,
        locations: [],
        startDate: moment().format('YYYY-MM-DD'),
        startTime: '09:00',
        endDate: moment().format('YYYY-MM-DD'),
        endTime: '17:00',
        allDay: false
    });
    const [currentClinic, setCurrentClinic] = useState([]);
    const [allowedClinics, setAllowedClinics] = useState([]);
    const [practitionerSchedules, setPractitionerSchedules] = useState([]);
    const [practitionerLeaves, setPractitionerLeaves] = useState([]);
    const [practitionersFilter, setPractitionersFilter] = useState([]);
    const [locationsFilter, setLocationsFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({});
    const [optionsJobs, setOptionsJobs] = useState([]);
    const [jobsFilter, setJobsFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterByInactives, setFilterByInactives] = useState(false);
    const currentClinicId = localStorage.getItem(CURRENT_CLINIC);

    useEffect(() => {
        reloadAllData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practitionersFilter, locationsFilter, dateFilter, filterByInactives, jobsFilter]);

    const reloadAllData = async () => {
        setIsLoading(true);
        const query = {};
        if (practitionersFilter.length) {
            query.practitioners = practitionersFilter.map(e => e.id);
        }
        if (jobsFilter.length) {
            query.jobs = jobsFilter.map(e => e.id);
        }
        if (locationsFilter.length) {
            query.location = locationsFilter.map(current => {
                return current.id;
            });
        }
        if (_.size(dateFilter)) {
            query.dayFrom = dateFilter.dayFrom;
            query.dayTo = dateFilter.dayTo;
        }
        if (filterByInactives) {
            query.active = !filterByInactives;
        }
        if (!query.location) {
            query.location = [localStorage.getItem('currentClinic')];
        }

        const [newPractitionerSchedules, newPractitionerLeaves] = await Promise.all([
            practitionerScheduleApi.query(query),
            practitionerLeaveApi.list(query)
        ]);
        setPractitionerSchedules(newPractitionerSchedules);
        setPractitionerLeaves(newPractitionerLeaves);

        setIsLoading(false);
    };

    useEffect(() => {
        const query = window.location.search;
        if (query === '') return;

        selectViewMode(query.split(/[%&=]/)[3]);
        onDateChange(moment(query.split(/[%&=]/)[1], 'DD-MM-YYYY').startOf('day'));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fetchFormData = async () => {
            const jobs = await JobApi.listAll();
            setOptionsJobs(jobs);
        };
        fetchFormData();
    }, []);

    useEffect(() => {
        dispatch(getClinicsAction());
        /*eslint-disable-next-line */
    }, []);

    useEffect(() => {
        if (clinics?.length) {
            (async () => {
                const user = await AuthApi.getMe();
                setAllowedClinics(clinics.filter(c => user.locations?.some(loc => loc.clinic === c.id)));
            })();
        }
    }, [clinics]);

    useEffect(() => {
        if (clinics?.length) {
            const findClinic = clinics.find(item => item.id === currentClinicId);
            setCurrentClinic(findClinic ? [findClinic] : []);
        }
    }, [clinics, currentClinicId]);

    const setViewMode = mode => {
        dispatch(changeViewMode(mode));
    };

    const selectViewMode = mode => {
        setPractitionerSchedules([]);
        if (SCHEDULE_VIEW_MODES.week !== mode) {
            setDateFilter({});
        }
        setViewMode(mode);
    };

    const onDateChange = (startDate, endDate = null) => {
        if (!endDate) {
            setDateFilter({
                dayFrom: startDate.clone().format('YYYY-MM-DD'),
                dayTo: startDate
                    .clone()
                    .add(6, 'days')
                    .format('YYYY-MM-DD')
            });
        } else {
            setDateFilter({
                dayFrom: startDate.clone().format('YYYY-MM-DD'),
                dayTo: endDate.clone().format('YYYY-MM-DD')
            });
        }
    };

    const setJobRoles = (evt, jobRoles) => {
        setJobsFilter(jobRoles);
    };

    const openNewLeaveModal = () => {
        setLeaveData(data => ({ ...data, open: true, clinic: localStorage.getItem(CURRENT_CLINIC) }));
    };

    const renderCalendar = () => {
        if (viewMode === SCHEDULE_VIEW_MODES.month) {
            return (
                <MonthView
                    filterByInactives={filterByInactives}
                    setFilterByInactives={setFilterByInactives}
                    openNewLeaveModal={openNewLeaveModal}
                    practitionerSchedules={practitionerSchedules}
                    practitionerLeaves={practitionerLeaves}
                    setDayPopupData={setDayPopupData}
                    setLeaveData={setLeaveData}
                    onDateChange={onDateChange}
                />
            );
        } else if (viewMode === SCHEDULE_VIEW_MODES.year) {
            return (
                <YearView
                    filterByInactives={filterByInactives}
                    setFilterByInactives={setFilterByInactives}
                    openNewLeaveModal={openNewLeaveModal}
                    practitionerSchedules={practitionerSchedules}
                    practitionerLeaves={practitionerLeaves}
                    setDayPopupData={setDayPopupData}
                    setLeaveData={setLeaveData}
                />
            );
        } else if (viewMode === SCHEDULE_VIEW_MODES.week) {
            return (
                <WeekView
                    openNewLeaveModal={openNewLeaveModal}
                    filterByInactives={filterByInactives}
                    setFilterByInactives={setFilterByInactives}
                    practitionerSchedules={practitionerSchedules}
                    practitionerFilters={practitionersFilter}
                    practitionerLeaves={practitionerLeaves}
                    setDayPopupData={setDayPopupData}
                    setLeaveData={setLeaveData}
                    onDateChange={onDateChange}
                />
            );
        }
    };

    const [showClinicTime, setShowClinicTime] = useState(false);

    const renderCalendarHeader = () => {
        const goToNewSchedule = () => history.push('/newSchedule');
        const buttons = (
            <div>
                <Button
                    onClick={goToNewSchedule}
                    variant="contained"
                    className={classNames(classes.button, classes.openButton)}
                >
                    New schedule
                </Button>
                <Button
                    onClick={openNewLeaveModal}
                    variant="contained"
                    className={classNames(classes.button, classes.openButton, classes.leftSpacing)}
                >
                    Book leave
                </Button>
                {showClinicTime && <ClinicAvailability onClose={() => setShowClinicTime(false)} />}
            </div>
        );
        return <div className={classNames(classes.row, classes.bottomSpacing)}>{buttons}</div>;
    };

    const renderCalendarFooter = () => {
        const flattenedSchedules = practitionerSchedules.flatMap(
            practitionerSchedule => practitionerSchedule.schedules
        );
        const flattenedLeaves = practitionerLeaves.flatMap(practitionerLeave => practitionerLeave.leaves);

        const leaves = day => {
            return getLeaves(flattenedLeaves, day).map((leaveTime, index) => {
                return (
                    <Typography className={classes.textAlignCenter} key={index}>
                        {leaveTime}
                    </Typography>
                );
            });
        };
        const shifts = day => {
            return (
                <Typography className={classes.textAlignCenter}>{getShifts(flattenedSchedules, day, flattenedLeaves)}</Typography>
            );
        };

        const resumedData =
            viewMode !== SCHEDULE_VIEW_MODES.week ? (
                <div>
                    {leaves()}
                    {shifts()}
                </div>
            ) : (
                <div />
            );

        return <div className={classNames(classes.row, classes.rightAlign, classes.topSpacing)}>{resumedData}</div>;
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <div className={classNames(classes.container, classes.viewSchedule)}>
                {/* Autocomplete labels */}

                <Typography className={classes.title} variant="h4">
                    Staff Schedules
                </Typography>
                {renderCalendarHeader()}
                <div className={classNames(classes.row, classes.itemSpacingContainer)}>
                    <div className={classNames(classes.row, classes.alignCenter)}>
                        <Typography>View:</Typography>

                        <IconButton
                            className={classes.modeButton}
                            onClick={() => selectViewMode(SCHEDULE_VIEW_MODES.week)}
                        >
                            {SCHEDULE_VIEW_MODES.week === viewMode ? <CalendarWeekSelected /> : <CalendarWeek />}
                        </IconButton>

                        <IconButton
                            className={classes.modeButton}
                            onClick={() => selectViewMode(SCHEDULE_VIEW_MODES.month)}
                        >
                            {SCHEDULE_VIEW_MODES.month === viewMode ? <CalendarMonthSelected /> : <CalendarMonth />}
                        </IconButton>

                        <IconButton
                            className={classes.modeButton}
                            onClick={() => selectViewMode(SCHEDULE_VIEW_MODES.year)}
                        >
                            {SCHEDULE_VIEW_MODES.year === viewMode ? <CalendarYearSelected /> : <CalendarYear />}
                        </IconButton>
                    </div>

                    <div
                        className={classNames(
                            classes.row,
                            classes.grow,
                            classes.itemSpacingContainer,
                            classes.alignCenter
                        )}
                    >
                        <Typography>Filters:</Typography>
                        {/* Autocompletes */}
                        <SelectPractitionerInput
                            multiple
                            className={classes.itemSpacing}
                            onSelect={practitioners => setPractitionersFilter(practitioners)}
                            value={practitionersFilter}
                        />
                        <Autocomplete
                            className={classes.itemSpacing}
                            fullWidth
                            autoComplete
                            autoHighlight
                            multiple
                            limitTags={1}
                            filterSelectedOptions
                            options={optionsJobs}
                            getOptionLabel={option => option.name}
                            onChange={setJobRoles}
                            renderInput={params => <TextField {...params} variant="outlined" label="Job Role" />}
                        />
                        <Autocomplete
                            className={classes.itemSpacing}
                            fullWidth
                            autoComplete
                            autoHighlight
                            limitTags={1}
                            multiple
                            options={OPTIONS}
                            onChange={() => {}}
                            renderInput={params => <TextField {...params} variant="outlined" label="Tags" />}
                        />
                        {clinics && clinics.length > 1 && (
                            <Autocomplete
                                className={classes.itemSpacing}
                                value={locationsFilter.length ? locationsFilter : currentClinic}
                                fullWidth
                                autoComplete
                                autoHighlight
                                limitTags={1}
                                multiple
                                options={allowedClinics}
                                getOptionLabel={opt => opt.accountName}
                                onChange={(e, value) => {
                                    setCurrentClinic([]);
                                    setLocationsFilter(value);
                                }}
                                renderInput={params => <TextField {...params} variant="outlined" label="Location" />}
                            />
                        )}
                    </div>
                </div>
                {renderCalendar()}
                {renderCalendarFooter()}
            </div>
            <DayPopupModal dayPopupData={dayPopupData} setDayPopupData={setDayPopupData} setLeaveData={setLeaveData} />
            <NewLeaveModal leaveData={leaveData} setLeaveData={setLeaveData} reloadAllData={reloadAllData} />
        </>
    );
}

ViewSchedule.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(ViewSchedule);
