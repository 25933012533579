import * as actions from '../actions/actionTypes';

const defaultStore = {
    templates: []
};

const scheduleReducer = (store = defaultStore, action) => {
    switch (action.type) {
        case actions.FETCHED_TEMPLATE_SCHEDULE:
            return {
                ...store,
                templates: action.payload
            };

        default:
            return store;
    }
};

export default scheduleReducer;
