export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const FETCHED_CLINICS = 'FETCHED_CLINICS';

export const FETCHED_PRACTITIONER = 'FETCHED_PRACTITIONER';
export const CREATED_PRACTITIONER_SCHEDULE = 'CREATED_PRACTITIONER_SCHEDULE';

export const FETCHED_TEMPLATE_SCHEDULE = 'FETCHED_TEMPLATE_SCHEDULE';

export const VIEW_MODE = 'VIEW_MODE';
