import globalTheme from '../collums-constants/styles/globalTheme';
import { createMuiTheme } from '@material-ui/core';
import { merge } from 'lodash';

const theme = {
    zIndex: {
        spinner: 2000,
        appBar: 1201,
        // defaults
        mobileStepper: 1000,
        speedDial: 1050,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500
    }
};

export default createMuiTheme(merge(globalTheme, theme));
