import React from 'react';

import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import practitionerLeaveApi from '../../api/practitionerLeaveApi';
import LEAVE_TYPES from '../../constants/leaveTypes';

import { sharedStyle, monthViewStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import getWhiteOrBlackColor from '../../services/getWhiteOrBlackColor';

const style = mergeStyles(commonStyle, sharedStyle, monthViewStyle);

function MonthView({
    classes,
    practitionerSchedules,
    filterByInactives,
    setFilterByInactives,
    practitionerLeaves,
    setDayPopupData,
    setLeaveData,
    onDateChange
}) {
    const history = useHistory();

    const calendarRef = React.useRef(null);

    const convertPractitionerSchedulesToEvents = data =>
        data.flatMap(item =>
            item.schedules.map(schedule => ({
                id: schedule.id,
                title: `${schedule.start}-${schedule.end} ${item.practitioner.displayName}`,
                start: Moment(schedule.date).toDate(),
                backgroundColor: item.practitioner.colour || '#000000',
                borderColor: item.practitioner.colour ? getWhiteOrBlackColor(item.practitioner.colour) : '#fff',
                textColor: item.practitioner.colour ? getWhiteOrBlackColor(item.practitioner.colour) : '#fff'
            }))
        );

    const convertPractitionerLeavesToEvents = data =>
        data.flatMap(item =>
            item.leaves.map(schedule => ({
                id: schedule.id,
                title: `Leave ${schedule.start}-${schedule.end} - ${item.practitioner.displayName}`,
                start: Moment(schedule.date).toDate(),
                backgroundColor: 'red',
                borderColor: item.practitioner.colour ? getWhiteOrBlackColor(item.practitioner.colour) : '#fff',
                textColor: item.practitioner.colour ? getWhiteOrBlackColor(item.practitioner.colour) : '#fff'
            }))
        );

    const goToWeekEdit = evt => {
        history.push({ pathname: '/schedule/edit', search: `?date=${evt.dateStr}` });
    };

    const openLeaveModal = async id => {
        const leaveData = await practitionerLeaveApi.query(id);
        const { practitioner, type, isPaid, locations, date, start, end, clinic } = leaveData;
        const leaveForm = {
            id,
            practitioner: practitioner,
            type: LEAVE_TYPES.filter(e => e.value === type)[0],
            isPaid,
            allDay: false, // this is just a flag sent to backend, it's value isn't saved
            locations,
            startDate: Moment(date, 'YYYY-MM-DD'),
            endDate: Moment(date, 'YYYY-MM-DD'),
            startTime: start,
            endTime: end,
            clinic
        };
        setLeaveData(data => ({ ...data, open: true, ...leaveForm }));
    };

    const openEvent = evt => {
        if (scheduleEvents.some(e => e.id === evt.event.id)) {
            const date = Moment(evt.event.start);
            const calendarApi = calendarRef.current.getApi();
            const events = calendarApi.getEvents();
            const dayEvents = events.filter(event => {
                return Moment(event.start).isSame(date, 'date');
            });
            setDayPopupData(dayPopupData => ({ ...dayPopupData, events: dayEvents, open: true, date }));
        } else {
            openLeaveModal(evt.event.id);
        }
    };

    const scheduleEvents = convertPractitionerSchedulesToEvents(practitionerSchedules);
    const leaveEvents = convertPractitionerLeavesToEvents(practitionerLeaves);
    const events = [...scheduleEvents, ...leaveEvents];

    return (
        <div
            className={classNames(
                classes.monthView,
                classes.monthCalendarHeader,
                classes.column,
                classes.calendarContainer,
                classes.todayHighlight
            )}
        >
            <div>
                <FormControlLabel
                    style={{ float: 'right' }}
                    control={
                        <Checkbox
                            checked={filterByInactives}
                            onChange={() => setFilterByInactives(!filterByInactives)}
                        />
                    }
                    label="Show inactive staff"
                />
            </div>
            <FullCalendar
                firstDay={1} //set Monday as first day
                fixedWeekCount={false} //hide 6th line if empty
                displayEventTime={false}
                height={450} //TODO: make this value variable according to screenSize
                defaultView="dayGridMonth"
                header={{
                    left: '',
                    center: 'prev today title next',
                    right: ''
                }}
                plugins={[dayGridPlugin, interactionPlugin]}
                dateClick={goToWeekEdit}
                eventClick={openEvent}
                ref={calendarRef}
                events={events}
                datesRender={arg => {
                    const start = Moment(arg.view.activeStart, 'DD-MM-YYYY');
                    const end = Moment(arg.view.activeEnd, 'DD-MM-YYYY');
                    end.subtract(1, 'day');
                    onDateChange(start, end);
                }}
                eventClassName={classes.calendarEvent}
            />
        </div>
    );
}

MonthView.propTypes = {
    classes: PropTypes.object.isRequired,
    filterByInactives: PropTypes.bool.isRequired,
    setFilterByInactives: PropTypes.func.isRequired,
    practitionerSchedules: PropTypes.array.isRequired,
    practitionerLeaves: PropTypes.array.isRequired,
    setDayPopupData: PropTypes.func.isRequired,
    setLeaveData: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired
};

export default withStyles(style)(MonthView);
