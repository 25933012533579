import React from 'react';

import { withStyles, Typography, Button, Modal, Paper, Switch } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, sharedStyle);

function CopyWeekModal({ classes, copyWeekData, setCopyWeekData, copyWeek, form }) {
    const onClose = () => setCopyWeekData(copyWeekData => ({ ...copyWeekData, open: false }));

    const onToggleAll = evt => {
        const newValue = evt.target.checked;
        const weekIndexTo = copyWeekData.weekIndexTo.map((e, i) =>
            i === copyWeekData.weekIndexFrom ? true : newValue
        );
        setCopyWeekData(copyWeekData => ({ ...copyWeekData, weekIndexTo }));
    };
    const onToggle = (evt, index) => {
        const newValue = evt.target.checked;
        const weekIndexTo = copyWeekData.weekIndexTo.map((e, i) => (i === index ? newValue : e));
        setCopyWeekData(copyWeekData => ({ ...copyWeekData, weekIndexTo }));
    };

    const { open, weekIndexFrom, weekIndexTo } = copyWeekData;

    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.copyModal)}>
                <div className={classNames(classes.row, classes.justifyCenter)}>
                    <Typography className={classes.modalTitle}>Copy Week {weekIndexFrom + 1} to:</Typography>
                </div>
                <div className={classes.column}>
                    <div className={classNames(classes.row, classes.alignCenter)}>
                        <Typography className={classes.centerLabel}>Select/Clear all</Typography>
                        <Switch
                            checked={weekIndexTo.slice(0, form.weeks.length).every(e => e)}
                            disabled={form.weeks.length === 1}
                            onClick={onToggleAll}
                        />
                    </div>
                    {copyWeekData.weekIndexTo.map((week, index) => (
                        <div className={classNames(classes.row, classes.alignCenter)} key={index}>
                            <Typography className={classes.centerLabel}>{index + 1}</Typography>
                            <Switch
                                checked={weekIndexTo[index]}
                                disabled={index === weekIndexFrom}
                                onClick={evt => onToggle(evt, index)}
                            />
                        </div>
                    ))}
                </div>
                <div className={classNames(classes.row, classes.justifyCenter, classes.itemSpacingContainer)}>
                    <Button
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classNames(classes.button, classes.confirmButton)}
                        variant="contained"
                        onClick={copyWeek}
                    >
                        Copy
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
}

CopyWeekModal.propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    copyWeekData: PropTypes.object.isRequired,
    setCopyWeekData: PropTypes.func.isRequired,
    copyWeek: PropTypes.func.isRequired
};

export default withStyles(style)(CopyWeekModal);
