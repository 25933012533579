import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppBar, Tabs, Tab, withStyles } from '@material-ui/core';
import querystring from 'querystring';
import PropTypes from 'prop-types';

import style from './style';
import ClinicSelector from '../../collums-components/components/common/ClinicSelector';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';

const mainTabsArray = [
    { value: 'schedule', label: 'Schedule' },
    { value: 'newSchedule', label: 'New schedule' }
];

const tabToNewWindow = tab => {
    if (['one', 'two', 'three', 'four'].includes(tab)) {
        return `/employees/${tab}`;
    } else {
        return tab === 'employees' ? '/employees/one' : `/${tab}`;
    }
};

const Header = ({ classes }) => {
    const location = useLocation();
    const history = useHistory();
    const [clinicId, setClinicId] = useState(localStorage.getItem(CURRENT_CLINIC));

    const getSelectedTab = level => location.pathname.split('/')[level];

    const renderTab = (tabObject, className, index) => {
        const navigate = tabToNewWindow(tabObject.value);

        return (
            <Tab
                key={index}
                className={className}
                value={tabObject.value}
                label={
                    <a href={navigate} onClick={e => e.preventDefault()}>
                        {tabObject.label}
                    </a>
                }
            />
        );
    };

    if (!getSelectedTab(1)) {
        return null;
    }

    const handleOnChangeClinic = clinic => {
        localStorage.setItem(CURRENT_CLINIC, clinic.id);
        setClinicId(clinic.id);

        if (location?.search) {
            const queryString = querystring.parse(location?.search ? location.search.replace('?', '') : '');
            queryString.clinic = clinic.id;
            history.replace({ pathname: location.pathname, search: querystring.stringify(queryString) });
        }

        window.location.reload();
    };

    return (
        <>
            <AppBar position="static" color="secondary" classes={{ root: classes.noShadow }}>
                <Tabs
                    variant="fullWidth"
                    value={getSelectedTab(1)}
                    className={classes.tabsContainer}
                    onChange={(e, value) => history.push(`/${value}`)}
                >
                    {mainTabsArray.map((tab, index) => renderTab(tab, classes.mainTab, index))}
                </Tabs>
            </AppBar>
            <div style={{ padding: '15px 35px 0' }}>
                <ClinicSelector clinicId={clinicId} onChange={handleOnChangeClinic} />
            </div>
        </>
    );
};

Header.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(Header);
