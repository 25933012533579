import api from './api';

const list = params => {
    return api.request({
        method: 'GET',
        url: '/practitioner-leaves',
        params
    });
};

const query = id => {
    return api.request({
        method: 'GET',
        url: `/practitioner-leaves/${id}`
    });
};

const create = data =>
    api.request({
        method: 'POST',
        url: '/practitioner-leaves',
        data
    });

const update = (id, data) =>
    api.request({
        method: 'PUT',
        url: `/practitioner-leaves/${id}`,
        data
    });

const remove = id =>
    api.request({
        method: 'DELETE',
        url: `/practitioner-leaves/${id}`
    });

export default {
    list,
    query,
    create,
    update,
    remove
};
