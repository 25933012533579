export const commonStyle = theme => ({
    // common components
    '& *': {
        color: '#000000DE'
    },
    container: {
        padding: theme.spacing(2, 4, 1, 4)
    },
    viewSchedule: {
        height: '100%'
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    modalPaper: {
        padding: '16px 16px 8px',
        overflowY: 'auto',
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    leaveModal: {
        width: 700
    },
    modalTitle: {
        fontWeight: '500'
    },
    button: {
        textTransform: 'none',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    calendarTable: {
        borderCollapse: 'collapse',
        width: '100%',
        '& td': {
            border: '1px solid black',
            verticalAlign: 'top'
        }
    },
    openButton: {
        backgroundColor: theme.palette.blue.main,
        padding: '6px 14px',
        fontWeight: 500,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.blue.hover
        }
    },
    confirmButton: {
        padding: '6px 14px',
        color: '#ffffff',
        backgroundColor: '#b41778',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: '#912d6b'
        }
    },
    cancelButton: {
        borderColor: '#575A64',
        backgroundColor: '#FCFCFC',
        fontWeight: 400
    },
    saveButton: {
        fontWeight: 500,
        backgroundColor: theme.palette.wine.main,
        color: theme.palette.white.main,
        '&:hover': {
            backgroundColor: theme.palette.wine.hover,
            color: theme.palette.white.main
        }
    },
    // 'monostyle' classes
    row: {
        display: 'flex',
        flexFlow: 'row nowrap'
    },
    column: {
        display: 'flex',
        flexFlow: 'column nowrap'
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    alignStretch: {
        alignItems: 'stretch'
    },
    alignStart: {
        alignItems: 'flex-start'
    },
    alignCenter: {
        alignItems: 'center'
    },
    alignEnd: {
        alignItems: 'flex-end'
    },
    itemSpacingContainer: {
        marginTop: 10,
        marginBottom: 10,
        '& > :not(:first-child)': {
            marginLeft: theme.spacing(2)
        },
        '& .MuiAutocomplete-root': {
            height: 'fit-content'
        },
        '& .MuiInputBase-root': {
            padding: 2,
            height: 'fit-content'
        },
        '& .MuiAutocomplete-tag': {
            marginTop: 1
        }
    },
    navigationWeek: {
        marginLeft: '30%'
    },

    inlineBlock: {
        display: 'inline-block'
    },
    grow: {
        flex: 1
    },
    topSpacing: {
        marginTop: theme.spacing(1)
    },
    topSpacing2: {
        marginTop: theme.spacing(2)
    },
    leftSpacing: {
        marginLeft: theme.spacing(1)
    },
    nowrapText: {
        whiteSpace: 'nowrap'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    todayButton: {
        fontSize: 16
    },
    title: {
        marginBottom: 10,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500
    },
    bottomSpacing: {
        marginBottom: 15
    },
    rightAlign: {
        justifyContent: 'flex-end'
    }
});

export const mergeStyles = (style1, style2, style3) => {
    const empty = () => ({});
    style1 = style1 || empty;
    style2 = style2 || empty;
    style3 = style3 || empty;
    return theme => ({ ...style1(theme), ...style2(theme), ...style3(theme) });
};
