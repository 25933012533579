export const sharedStyle = theme => ({
    endLabel: {
        marginTop: theme.spacing(1)
    },
    copyModal: {
        width: 240
    },
    saveTemplateModal: {
        width: 300
    },
    manageTemplateModal: {
        width: 350
    },
    modalDescription: {
        textAlign: 'center'
    },
    centerLabel: {
        flex: '1 0 0px',
        textAlign: 'center'
    },
    verticalSpacing: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    toastrWrapper: {
        width: '400px !important'
    }
});

export const newScheduleStyle = theme => ({
    endDateSection: {
        marginBottom: 4,
        '& .MuiFormControlLabel-root': {
            marginRight: 30
        }
    },
    occurrencesSection: {
        marginBottom: 6,
        '& .MuiAutocomplete-inputRoot': {
            padding: '2px 0'
        },
        '& fieldset': {
            borderColor: theme.palette.black.full
        },
        '& .MuiAutocomplete-root': {
            width: 140,
            marginRight: 12
        },
        '& .suffix': {}
    },
    bottomButtons: {
        marginTop: 12
    }
});

export const selectTemplateInputStyle = () => ({});

export const weekStyle = () => ({
    newWeekSchedule: {
        '& td': {
            width: `${100 / 7}%`
        }
    }
});

export const templateItemStyle = () => ({
    wrapTemplateItem: {
        border: '1.5px solid black',
        borderRadius: '0.2rem',
        height: '2.5rem',
        paddingLeft: '0.5rem',
        margin: '0.3rem 0',
        '& .MuiSwitch-root': {
            float: 'right'
        }
    },
    wrapTemplateText: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        float: 'left'
    },
    modalTemplateSpacing: {
        padding: '1.3rem'
    }
});
