import React from 'react';

import { withStyles, Typography, TextField } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
const style = mergeStyles(commonStyle, sharedStyle);

function CopyDayModal({ classes, saveTemplateData, setSaveTemplateData, saveTemplate }) {
    const onClose = () => setSaveTemplateData(saveTemplateData => ({ ...saveTemplateData, open: false }));

    const onChangeTemplateName = evt => {
        const templateName = evt.target.value;
        setSaveTemplateData(saveTemplateData => ({ ...saveTemplateData, templateName }));
    };

    const { open, templateName } = saveTemplateData;
    return (
        <CancelContinueModal
            open={open}
            onContinue={saveTemplate}
            onCancel={onClose}
            continueButtonText={'Save'}
            title={'Save schedule template'}
            content={
                <div style={{ flexFlow: 'column' }}>
                    <Typography className={classes.modalDescription}>
                        This template will copy all shift details but not the recurrence
                    </Typography>
                    <div
                        className={classNames(
                            classes.row,
                            classes.justifyCenter,
                            classes.itemSpacingContainer,
                            classes.alignCenter,
                            classes.verticalSpacing
                        )}
                    >
                        <Typography className={classes.itemSpacing}>Template name</Typography>
                        <TextField
                            className={classes.itemSpacing}
                            value={templateName}
                            onChange={onChangeTemplateName}
                        />
                    </div>
                </div>
            }
        />
    );
}

CopyDayModal.propTypes = {
    classes: PropTypes.object.isRequired,
    saveTemplateData: PropTypes.object.isRequired,
    setSaveTemplateData: PropTypes.func.isRequired,
    saveTemplate: PropTypes.func.isRequired
};

export default withStyles(style)(CopyDayModal);
