import React from 'react';

import { Button, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';

import practitionerLeaveApi from '../../api/practitionerLeaveApi';

import { commonStyle, mergeStyles } from '../../style/common';
import { sharedStyle } from './style';
const style = mergeStyles(commonStyle, sharedStyle);

// eslint-disable-next-line no-unused-vars
const DeleteLeaveButton = ({ classes, leaveData, closeModals, reloadAllData = () => {} }) => {
    const deleteLeave = async () => {
        await practitionerLeaveApi.remove(leaveData.id);
        reloadAllData();
        toastr.success('Leave deleted');
        closeModals();
    };

    return (
        <Button className={classNames(classes.button, classes.saveButton)} onClick={deleteLeave} variant="outlined">
            Delete
        </Button>
    );
};

DeleteLeaveButton.propTypes = {
    leaveData: PropTypes.object.isRequired,
    closeModals: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    reloadAllData: PropTypes.object
};

export default withStyles(style)(DeleteLeaveButton);
