export const sharedStyle = theme => ({
    confirmButton: {
        backgroundColor: theme.palette.gray.main,
        color: theme.palette.white.main
    },
    copyModal: {
        width: 200
    },
    saveTemplateModal: {
        width: 300
    },
    modalDescription: {
        textAlign: 'center'
    },
    centerLabel: {
        flex: '1 0 0px',
        textAlign: 'center'
    },
    verticalSpacing: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    editWeekSchedule: {
        borderBottom: '1px solid black'
    }
});

export const editPractitionerWeekScheduleStyle = () => ({
    practitionerWeekSchedule: {
        borderRight: '1px solid black'
    }
});

export const selectTemplateInputStyle = () => ({});
