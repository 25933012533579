import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';

export const sharedStyle = theme => ({
    calendarContainer: {
        // marginTop: theme.spacing(2)
    },
    eventContainer: {
        cursor: 'pointer',
        borderRadius: 3,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        margin: '2px 2px 0px 2px'
    },
    modeButton: {
        padding: 0,
        '& .material-icons': {
            width: 48,
            height: 48
        }
    },
    modalMargins: {
        padding: '12px 24px'
    }
});

export const monthViewStyle = theme => ({
    monthView: {
        height: 'calc(100% - 185px)',
        overflow: 'hidden',
        '& .fc.fc-ltr.fc-unthemed': {
            overflow: 'auto',
            paddingBottom: 16
        },
        '& .fc-scroller': {
            height: 'unset!important'
        },
        '& .fc th': {
            fontWeight: 500
        }
    },
    calendarEvent: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        cursor: 'pointer',
        fontSize: 14
    },
    todayHighlight: {
        '& .fc-today': {
            background: 'rgba(212, 231, 255, 1) !important'
        }
    },
    monthCalendarHeader: {
        '& .fc-toolbar.fc-header-toolbar': {
            marginBottom: '0px !important'
        },
        '& .fc-center': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .fc-center h2': {
            fontSize: '18px !important',
            fontFamily: 'Roboto, Arial, Sans-serif',
            minWidth: 145,
            textAlign: 'center',
            fontWeight: 'normal'
        },
        '& .fc-today-button': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            textTransform: 'capitalize',
            borderColor: 'rgba(0,0,0,0.23)',
            padding: '6px 14px',
            marginLeft: 40,
            fontSize: 16,
            borderRadius: 3.5
        },
        '& .fc-button-primary': {
            filter: 'brightness(0)',
            backgroundColor: 'unset',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
            },
            '&:disabled': {
                backgroundColor: 'rgba(0,0,0,0.04)'
            },
            '&:active': {
                boxShadow: 'unset !important'
            },
            textTransform: 'capitalize',
            fontFamily: 'Roboto, Arial, Sans-serif',
            '&:not(.fc-today-button)': {
                borderWidth: 0,
                borderRadius: 50,
                padding: 12
            }
        }
    }
});

export const yearViewStyle = () => ({
    calendarHeaderNavMargin: {
        marginLeft: 25
    },
    calendarHeaderText: {
        fontSize: 16,
        minWidth: 100,
        textAlign: 'right'
    },
    calendarHeader: {
        marginTop: 2,
        marginBottom: '0.5px !important'
    },
    todayButton: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        textTransform: 'capitalize',
        borderColor: 'rgba(0,0,0,0.23)',
        padding: '3px 15px',
        paddingBottom: '4px !important',
        fontSize: 16,
        borderRadius: 3.5,
        marginLeft: 25,
        textAlign: 'center'
    },
    yearlyCalendar: {
        display: 'flex',
        justifyContent: 'center',

        '& table.calendar': {
            borderCollapse: 'collapse'
        },

        '& table.calendar thead': {
            backgroundColor: '#5a5a5a',
            color: 'white',
            marginBottom: '3px',
            borderBottom: '2px solid white'
        },

        '& table.calendar thead th': {
            fontWeight: 'lighter',
            padding: '10px 3px'
        },

        '& table.calendar thead th.bolder': {
            // fontWeight: 'bold'
        },

        '& table.calendar tbody': {
            fontSize: 14
        },

        '& table.calendar td': {
            textAlign: 'center',
            padding: '8px',
            cursor: 'pointer',
            border: '1px solid rgba(185, 185, 185, 1)',
            minWidth: '15px'
        },

        '& table.calendar tr:last-child td': {
            borderBottom: 'none'
        },

        '& table.calendar td.month-name': {
            fontWeight: 'bold',
            textAlign: 'left',
            cursor: 'default',
            borderLeft: 'none'
        },

        '& table.calendar td.prev-mont:h, table.calendar td.next-month': {
            color: 'transparent',
            cursor: 'default',
            pointerEvents: 'none'
        },

        '& table.calendar td.week-separator': {
            pointerEvents: 'none',
            padding: '0',
            width: '8px',
            minWidth: '0'
        },

        '& table.calendar td.weekend': {
            fontStyle: 'italic',
            fontWeight: 'bold',
            backgroundColor: '#e5e5e5'
        },

        '& table.calendar td.bolder': {
            fontWeight: 'bold'
        },

        '& table.calendar td.selected': {
            '-webkit-box-shadow': 'inset 0px 0px 1px 1px rgba(0,0,0,1)',
            '-moz-box-shadow': 'inset 0px 0px 1px 1px rgba(0,0,0,1)',
            boxShadow: 'inset 0px 0px 1px 1px rgba(0,0,0,1)'
        },

        '& div.calendar-controls': {
            margin: '5px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '25px'
        },

        '& div.calendar-controls>.control:first-child': {
            marginRight: 82
            // visibility: 'hidden',
            // '&:after': {
            //     content: 'goodbye',
            //     visibility: 'visible',
            //     display: 'block',
            //     position: 'absolute',
            //     backgroundColor: 'red',
            //     padding: '5px',
            //     top: '2px'
            // }
        },

        '& div.calendar-controls div': {
            display: 'block'
        },

        '& div.calendar-controls .current-year': {
            margin: '0 20px',
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 'bold',
            width: 53,
            textAlign: 'center'
        },

        '& div.calendar-controls .control': {
            color: '#323232',
            fontSize: '23px',
            cursor: 'pointer'
        },

        '& div.calendar-controls .today': {
            // BEFORE
            // position: 'relative',
            // left: '420px',
            // lineHeight: '35px',
            // fontSize: '0.6em',
            // NOW
            textTransform: 'capitalize',
            borderRadius: 3.5,
            fontSize: 10,
            fontFamily: 'Roboto, Arial, Sans-serif',
            padding: '6px 14px',
            marginLeft: '-175px',
            border: '1px solid rgba(0,0,0,0.23)',
            lineHeight: '17.5px',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)'
            }
        }
    },
    legendContainer: {
        marginTop: 48,
        marginRight: 16
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 4
    },
    legendColorBox: {
        minWidth: 32,
        height: 32,
        marginRight: 8
    }
});

export const weekViewStyle = () => ({
    weekCalendar: {
        borderBottom: '1px solid black'
    },
    weekCalendarHeaderDate: {
        fontSize: '16px !important',
        minWidth: '231px'
    }
});

export const viewPractitionerWeekScheduleStyle = () => ({
    practitionerWeekSchedule: {
        borderRight: '1px solid black'
    }
});

export const weekDayCalendarStyle = theme => ({
    weekDay: {
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
        flex: '1 1 0px',
        backgroundColor: theme.palette.gray.lighter
    },
    weekDayHeader: {
        backgroundColor: theme.palette.gray.light,
        justifyContent: 'center'
    },
    weekDayContent: {
        padding: theme.spacing(0, 1)
    },
    weekDayLabel: {
        flex: '1 0 0px'
    },
    weekDayField: {
        flex: '1 0 0px'
    },
    onlyTextSpacing: {
        paddingTop: 4.71,
        paddingBottom: 4.71
    },
    checkBoxField: {
        padding: '6.15px'
    }
});

export const newLeaveStyle = () => ({
    newLeaveTableForm: {
        '& tr td': {
            verticalAlign: 'top'
        },
        '& tr td:first-child': {
            width: 230
        },
        '& tr td:last-child': {
            width: 330
        }
    },
    noVerticalPadding: {
        paddingTop: 0,
        paddingBottom: 0
    },
    selectPractitionerInput: {
        width: 300
    },
    noTopMargin: {
        '& .MuiFormControl-marginDense': {
            marginTop: 0
        }
    }
});
