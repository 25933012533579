import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NewSchedule from './components/newSchedule/index';
import ViewSchedule from './components/viewSchedule/index';
import EditSchedule from './components/editSchedule/index';

function Routes() {
    return (
        <Switch>
            <Redirect exact from="/" to="schedule" />
            <Route exact path="/schedule" component={ViewSchedule} />
            <Route exact path="/newSchedule" component={NewSchedule} />
            <Route exact path="/schedule/edit" component={EditSchedule} />
        </Switch>
    );
}

export default Routes;
