import React from 'react';

import { withStyles, Typography, IconButton, Button, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { Calendar } from 'react-yearly-calendar';
import { useHistory } from 'react-router-dom';
import Moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ChevronBack, ChevronForward } from '../../assets/icons';

import { sharedStyle, yearViewStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import getWhiteOrBlackColor from '../../services/getWhiteOrBlackColor';
const style = mergeStyles(commonStyle, sharedStyle, yearViewStyle);

const today = Moment.utc();

const groupDates = (acc, el) => {
    let newAcc = [...acc];
    const index = acc.findIndex(accObj => accObj.date === el.date);
    if (index !== -1) {
        newAcc[index].schedules = [...newAcc[index].schedules, ...el.schedules];
    } else {
        newAcc.push(el);
    }
    return newAcc;
};

const groupClasses = (acc, el) => {
    let newAcc = [...acc];
    const index = acc.findIndex(accObj => accObj.className === el.className);
    if (index !== -1) {
        newAcc[index].dates = [...newAcc[index].dates, ...el.dates];
    } else {
        newAcc.push(el);
    }
    return newAcc;
};

const convertPractitionerSchedulesToDateSchedules = practitionerSchedules => {
    const ungroupedDates = practitionerSchedules.flatMap(practitionerSchedule => {
        const { practitioner, schedules, leaves } = practitionerSchedule;
        const practitionerDates = (schedules || leaves).map(schedule => {
            const { start, end, id } = schedule;
            const date = Moment(schedule.date).format('YYYY-MM-DD');
            // object that will be in the place of practitioner
            const colorSchedule = {
                backgroundColor: practitioner.colour || '#000',
                borderColor: getWhiteOrBlackColor(practitioner.colour),
                textColor: getWhiteOrBlackColor(practitioner.colour)
            };
            const dateSchedules = {
                date,
                schedules: [
                    {
                        id,
                        practitioner,
                        start,
                        end,
                        eventLayout: colorSchedule
                    }
                ]
            };
            return dateSchedules;
        });
        return practitionerDates;
    });
    const dates = ungroupedDates.reduce(groupDates, []);
    return dates;
};

const convertDateSchedulesToClassObjects = dates => {
    const classObjects = dates.map(date => {
        const practitioners = date.schedules.map(schedule => schedule.practitioner.displayName);
        const backgroundColors = date.schedules.map(schedule => schedule.eventLayout.backgroundColor);
        const textColors = date.schedules.map(schedule => schedule.eventLayout.textColor);
        const className = practitioners
            .join('')
            .split(' ')
            .join('');
        const dates = [date.date];
        return {
            className,
            practitioners,
            backgroundColors,
            textColors,
            dates
        };
    });
    const classes = classObjects.reduce(groupClasses, []);
    return classes;
};

const convertClassObjectsToCustomClasses = classObjects => {
    const classes = {};
    classObjects.forEach(classObjects => {
        const { className, backgroundColors, textColors } = classObjects;
        const bgColors = backgroundColors.map(
            (color, index, arr) =>
                `, ${color} ${(100 * index) / arr.length}%, ${color} ${(100 * (index + 1)) / arr.length}%`
        );
        const background = `linear-gradient(to bottom${bgColors.join('')})`;
        const color = textColors[Math.floor(textColors.length / 2)];
        classes[className] = {
            background,
            color
        };
    });
    return classes;
};

function YearView({
    classes,
    practitionerSchedules,
    practitionerLeaves,
    setDayPopupData,
    filterByInactives,
    setFilterByInactives
}) {
    const history = useHistory();

    const [year, setYear] = React.useState(today.year());
    const [selectedDay, setSelectedDay] = React.useState(today);

    const dateSchedules = React.useMemo(
        () => convertPractitionerSchedulesToDateSchedules([...practitionerSchedules, ...practitionerLeaves]),
        [practitionerSchedules, practitionerLeaves]
    );
    const classObjects = React.useMemo(() => convertDateSchedulesToClassObjects(dateSchedules), [dateSchedules]);
    const useStyles = React.useMemo(() => makeStyles(convertClassObjectsToCustomClasses(classObjects)), [classObjects]);
    const customClasses = useStyles();
    const customCssClasses = {};
    classObjects.forEach(el => {
        customCssClasses[customClasses[el.className]] = el.dates;
    });
    customCssClasses.weekend = 'Sat,Sun';

    const openDayPopup = date => {
        const dayArray = dateSchedules.filter(obj => obj.date === date.format('YYYY-MM-DD'));
        if (dayArray[0]) {
            const day = dayArray[0];
            const events = day.schedules.map(schedule => {
                const { start, end, practitioner, eventLayout, id } = schedule;
                const title = `${start}-${end} ${practitioner.displayName}`;
                return { ...eventLayout, title, id };
            });
            setDayPopupData(dayPopupData => ({ ...dayPopupData, open: true, date, events }));
        } else {
            history.push({ pathname: '/schedule/edit', search: `?date=${date.format('YYYY-MM-DD')}` });
        }
    };

    const onPrevYear = () => {
        setYear(year - 1);
    };

    const onNextYear = () => {
        setYear(year + 1);
    };

    const goToToday = () => {
        setSelectedDay(today);
        setYear(today.year());
    };

    return (
        <>
            <div className={classNames(classes.column)}>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterByInactives}
                                onChange={() => setFilterByInactives(!filterByInactives)}
                            />
                        }
                        label="Show inactive staff"
                        style={{ float: 'right' }}
                    />
                </div>
                <div
                    className={classNames(
                        classes.calendarHeader,
                        classes.row,
                        classes.alignCenter,
                        classes.justifyCenter
                    )}
                >
                    <IconButton onClick={onPrevYear}>
                        <ChevronBack variant="small" />
                    </IconButton>
                    <Button
                        className={classNames(classes.capitalize, classes.todayButton)}
                        onClick={goToToday}
                        variant="outlined"
                    >
                        Today
                    </Button>
                    <Typography className={classes.calendarHeaderText}>{year}</Typography>
                    <IconButton className={classes.calendarHeaderNavMargin} onClick={onNextYear}>
                        <ChevronForward variant="small" />
                    </IconButton>
                </div>
                <div className={classNames(classes.calendarContainer, classes.yearlyCalendar)}>
                    <div className={classes.legendContainer}>
                        {practitionerSchedules.map((scheduleObject, index) => {
                            return (
                                <div key={index} className={classes.legendItem}>
                                    <div
                                        className={classes.legendColorBox}
                                        style={{ backgroundColor: scheduleObject.practitioner.colour || '#000' }}
                                    />
                                    <Typography>{scheduleObject.practitioner.displayName}</Typography>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        {/* <CalendarControls
                        year={year}
                        showTodayButton={true}
                        onPrevYear={onPrevYear}
                        onNextYear={onNextYear}
                        goToToday={goToToday}
                        customClasses={customCssClasses}
                    /> */}
                        <Calendar
                            showDaysOfWeek={true}
                            showWeekSeparators={false}
                            firstDayOfWeek={1}
                            year={year}
                            selectedDay={selectedDay}
                            onPickDate={openDayPopup}
                            customClasses={customCssClasses}
                            titles={() => 'title'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

YearView.propTypes = {
    classes: PropTypes.object.isRequired,
    practitionerSchedules: PropTypes.array.isRequired,
    filterByInactives: PropTypes.bool.isRequired,
    setFilterByInactives: PropTypes.func.isRequired,
    practitionerLeaves: PropTypes.array.isRequired,
    setDayPopupData: PropTypes.func.isRequired
};

export default withStyles(style)(YearView);
