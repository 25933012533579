import React, { useEffect, useState } from 'react';
/* import { useLocation } from 'react-router-dom';
 */
import PropTypes from 'prop-types';
import Classnames from 'classnames';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import { Autocomplete } from '@material-ui/lab';
import { withStyles, Typography, TextField, Checkbox, IconButton, Popper, Box } from '@material-ui/core';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import DeleteIcon from '@material-ui/icons/Delete';

import { Copy } from '../../assets/icons';

import LoadingScreen from '../../collums-components/components/common/loadingScreen';

import { weekDayStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import LEAVE_TYPES from '../../constants/leaveTypes';
/* import practitionerApi from '../../api/practitionerApi'; */
/* import ConfirmModal from './confirmModal'; */

const style = mergeStyles(commonStyle, weekDayStyle);

MomentDurationFormat(Moment);

const DATE = {
    start: '00:00',
    startHour: '00',
    startMin: '00',
    end: '23:55',
    endHour: '23',
    endMin: '55',
    interval: 5
};

const getHourOptions = () => {
    const durationPresets = new Array(288).fill().map((e, i) => {
        return Moment(DATE.start, 'HH:mm')
            .add(DATE.interval * i, 'minutes')
            .format('HH:mm');
    });
    return durationPresets;
};

const hourOptions = getHourOptions();

const PopperClinicComponent = props => (
    <Popper {...props} style={{ minWidth: 60 }}>
        {props.children}
    </Popper>
);

const PopperTimesComponent = props => (
    <Popper {...props} style={{ minWidth: 20 }}>
        {props.children}
    </Popper>
);

function WeekDay({
    classes,
    day,
    setDay,
    weekIndex,
    dayIndex,
    openCopyDayModal,
    clinics,
    showCopyDay = false,
    practitioner = null,
    setDeleteLeaveId = () => {}
}) {
    const [startHour, setStartHour] = useState({
        selected: day.start,
        options: hourOptions.slice(0, hourOptions.length - 1)
    });
    const [endHour, setEndHour] = useState({
        selected: day.end,
        options: hourOptions.slice(1, hourOptions.length)
    });
    const [assignedClinics, setAssignedClinics] = useState(clinics);

    useEffect(() => {
        setUpAssignedClinics();
        let start = null;
        let end = null;
        if (startHour.selected !== day.start) {
            start = day.start;
        }
        if (endHour.selected !== day.end) {
            end = day.end;
        }
        if (start || end) handleTime(start, end);
        //eslint-disable-next-line
    }, [day]);

    const [duration, setDuration] = useState('');
    /*     const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [appointmentsCount, setAppointmentsCount] = useState(0); */
    const [isLoading] = useState(false);

    /* const { search } = useLocation();
    const currentWeek = search.split('=')[1]; */

    function spliceString(str, index, count, add) {
        const ar = str.split('');
        ar.splice(index, count, add);
        return ar.join('');
    }

    /* const getCurrentDate = (isISOFormatted) => {
        let date = Moment(currentWeek)
            .startOf('week')
            .add(dayIndex + 1, 'days');
        if (isISOFormatted) date = date.toISOString();
        else date = date.format('DD/MM/YYYY');
        return date;
    }; */

    function getFormatedTime(time) {
        if (!time.includes(':')) {
            const timeFormated = spliceString(time, 2, 0, ':');

            if (timeFormated.length >= 6) return timeFormated.slice(0, -1);

            return timeFormated;
        }

        return time;
    }

    const setUpAssignedClinics = () => {
        if (!practitioner) {
            setAssignedClinics(clinics);
            return true;
        }

        const practitionerClinics = practitioner?.locations?.map(location => location.clinic);
        setAssignedClinics(clinics.filter(clinic => practitionerClinics.includes(clinic.id)));
        return true;
    };

    const handleTime = (startTime, endTime) => {
        let initialStart = startTime || startHour.selected;
        let initialEnd = endTime || endHour.selected;

        if (initialStart.length === 2) initialStart = `00:${initialStart}`;
        else if (initialStart.length === 3)
            initialStart = `0${initialStart[0]}:${initialStart.substr(initialStart.length - 2, 2)}`;

        if (initialEnd.length === 2) initialEnd = `00:${initialEnd}`;
        else if (initialEnd.length === 3)
            initialEnd = `0${initialEnd[0]}:${initialEnd.substr(initialEnd.length - 2, 2)}`;

        initialStart = getFormatedTime(initialStart);
        initialEnd = getFormatedTime(initialEnd);

        // Date hour/minute will be received as string.
        // If any is received, use the default values
        const time = {
            startH: initialStart.match(/\d*/gi)[0] || DATE.startHour,
            startM: initialStart.match(/\d*/gi)[2] || DATE.startMin,
            endH: initialEnd.match(/\d*/gi)[0] || DATE.endHour,
            endM: initialEnd.match(/\d*/gi)[2] || DATE.endMin
        };

        // Handle the manual input
        if (time.startH.length === 1) {
            initialStart = `0${initialStart}`;
            time.startH = `0${time.startH}`;
        }

        // Mount the current start and end with the date passed
        const start = new Date().setHours(time.startH, time.startM, 0);
        const end = new Date().setHours(time.endH, time.endM, 0);

        // The result of equation (60 - time.startM) / 15, gives us the
        // number intervals of 15 minutes missing to set up a full hour.
        // If there is a 00 minute, you already have a full hour

        let position;
        if (start) {
            position = hourOptions.indexOf(initialStart) + 1;
        }

        if (position === undefined) position = 1;

        // Fill with the time between start and end with interval of 15 minutes

        const dates = hourOptions.slice(position, hourOptions.length);

        // If the start value is greater than or equal to the end,
        // update the end, keeping it always some minutes ahead of the start

        initialEnd = start >= end ? dates[0] : initialEnd;

        // Set new Options
        setStartHour({
            selected: initialStart,
            options: !startHour.options.length ? removeLast(dates) : startHour.options
        });

        if (dates[dates.length - 1] === '00:00') dates.pop();

        setEndHour({
            selected: initialEnd,
            options: dates
        });

        // Display time between two dates
        handleDuration(initialStart, initialEnd);

        // Send new values to the parent
        const newDay = { ...day, start: initialStart, end: initialEnd };
        setDay(dayIndex, newDay);

        document.activeElement.blur();
    };

    const removeLast = arr => {
        arr.pop();
        return arr;
    };

    const setWorking = async working => {
        // setIsLoading(true);
        working = working.target.checked;
        const newDay = { ...day, working };
        /* if (!working && practitioner) {
            const date = getCurrentDate(true);
            const appointmentsOnDay = await practitionerApi.findExistingAppointments(date, practitioner.id);
            if (appointmentsOnDay.count) {
                setIsConfirmModalOpen(true);
                setAppointmentsCount(appointmentsOnDay.count);
                setIsLoading(false);
                return;
            }
        } */
        day = newDay;
        newDay.end = endHour.selected;
        newDay.start = startHour.selected;

        const selectedClinic = localStorage.getItem(CURRENT_CLINIC);

        if (working) {
            if (selectedClinic) {
                newDay.clinic = selectedClinic;
            } else if (clinics.length < 2) {
                newDay.clinic = clinics[0].id;
            }
        } else {
            delete newDay.clinic;
        }

        setDay(dayIndex, newDay, false);
        // setIsLoading(false);
    };

    const setLocation = (event, clinic) => {
        const newDay = { ...day, clinic };
        setDay(dayIndex, newDay);
    };

    const handleDuration = (start, end) =>
        setDuration(
            Moment.duration(
                Moment.utc(end, 'HH:mm').diff(Moment.utc(start, 'HH:mm'), 'minutes'),
                'minutes'
            ).format('HH:mm', { trim: false })
        );

    const checkValidDate = date => {
        const time = getFormatedTime(date);

        let hour = time.match(/\d*/gi)[0];
        let min = time.match(/\d*/gi)[2];

        // Handles input date
        if (hour.length === 1) {
            hour = `0${hour}`;
            date = `0${date}`;
        }

        if (time.length !== 5 || +hour < 6 || +hour > 17 || +min % 15 !== 0) return false;
        return true;
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <td className={Classnames(classes.weekDay)}>
                <div>
                    {day.leaves &&
                        day.leaves.map(leave => (
                            <Box key={leave.id} className={classes.leave}>
                                <Typography></Typography>
                                <Typography>
                                    <span>
                                        Leave {leave.start} - {leave.end}
                                    </span>
                                    <span>
                                        {leave.type ? LEAVE_TYPES.find(lt => lt.value === leave.type)?.label : ''}
                                    </span>
                                </Typography>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setDeleteLeaveId(leave.id);
                                    }}
                                >
                                    <DeleteIcon style={{ color: '#fff' }} />
                                </IconButton>
                            </Box>
                        ))}
                </div>
                <table className={classes.weekDayContent}>
                    <tbody>
                        {/* Working boolean */}
                        <tr>
                            <td>
                                <div className={classes.weekDayLabel}>
                                    <Typography>Working</Typography>
                                </div>
                            </td>
                            <td>
                                <Checkbox
                                    className={classes.checkBoxField}
                                    size="small"
                                    checked={day.working}
                                    onChange={setWorking}
                                />
                            </td>
                        </tr>
                        {/* Location */}
                        {day.working && (
                            <>
                                {/* Locations */}
                                <tr>
                                    <td>
                                        <div className={classes.weekDayLabel}>
                                            <Typography>Clinic</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={classes.weekDayField}>
                                            <Autocomplete
                                                options={assignedClinics}
                                                autoHighlight
                                                disableClearable
                                                PopperComponent={PopperClinicComponent}
                                                getOptionSelected={option => day?.clinic === option.id}
                                                getOptionLabel={option => option?.accountName || ''}
                                                value={
                                                    assignedClinics.length > 1
                                                        ? day?.clinic
                                                            ? assignedClinics.find(clinic => clinic.id === day.clinic)
                                                            : { accountName: '' }
                                                        : assignedClinics[0]
                                                }
                                                onChange={(e, value) => {
                                                    setLocation(e, value.id);
                                                }}
                                                renderInput={params => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* Start time */}
                                <tr>
                                    <td>
                                        <div className={classes.weekDayLabel}>
                                            <Typography>Start</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        {/* Start time */}
                                        <div className={classes.weekDayField}>
                                            <Autocomplete
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                options={startHour.options}
                                                value={startHour.selected}
                                                PopperComponent={PopperTimesComponent}
                                                onChange={(event, value) => {
                                                    handleTime(value, null);
                                                }}
                                                onKeyDown={event =>
                                                    event.target.value.length >= 4 &&
                                                    (event.key === 'Enter' || event.key === 'Tab')
                                                        ? checkValidDate(event.target.value) &&
                                                          handleTime(event.target.value, null)
                                                        : null
                                                }
                                                renderInput={params => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* End time */}
                                <tr>
                                    <td>
                                        <div className={classes.weekDayLabel}>
                                            <Typography>End</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={classes.weekDayField}>
                                            <Autocomplete
                                                autoComplete
                                                autoHighlight
                                                disableClearable
                                                onChange={(event, value) => {
                                                    handleTime(null, value);
                                                }}
                                                options={endHour.options}
                                                value={endHour.selected}
                                                PopperComponent={PopperTimesComponent}
                                                onKeyDown={event =>
                                                    event.target.value.length >= 4 &&
                                                    (event.key === 'Enter' || event.key === 'Tab')
                                                        ? checkValidDate(event.target.value) &&
                                                          handleTime(null, event.target.value)
                                                        : null
                                                }
                                                renderInput={params => <TextField {...params} fullWidth />}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {/* Hours */}
                                <tr>
                                    <td>
                                        <div className={classes.weekDayLabel}>
                                            <Typography>Hrs:</Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            className={Classnames(
                                                classes.weekDayField,
                                                classes.row,
                                                classes.spaceBetween,
                                                classes.alignCenter
                                            )}
                                        >
                                            <Typography>{duration}</Typography>
                                            {showCopyDay && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => openCopyDayModal(weekIndex, dayIndex)}
                                                >
                                                    <Copy />
                                                </IconButton>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </td>
            {/*             <ConfirmModal
                title="Practitioner has booked appointments"
                message={`There is ${appointmentsCount} booked appointment${appointmentsCount > 1 ? 's' : ''} for ${
                    practitioner ? practitioner.displayName : ''
                } at ${getCurrentDate(false)}`}
                open={isConfirmModalOpen}
                onCancel={() => setIsConfirmModalOpen(false)}
                onConfirm={() => {
                    setIsConfirmModalOpen(false);
                }}
            /> */}
        </>
    );
}

PopperClinicComponent.propTypes = {
    children: PropTypes.element.isRequired
};

PopperTimesComponent.propTypes = {
    children: PropTypes.element.isRequired
};

WeekDay.propTypes = {
    classes: PropTypes.object.isRequired,
    day: PropTypes.object.isRequired,
    setDay: PropTypes.func.isRequired,
    clinics: PropTypes.object.isRequired,
    openCopyDayModal: PropTypes.func.isRequired,
    weekIndex: PropTypes.number.isRequired,
    dayIndex: PropTypes.number.isRequired,
    showCopyDay: PropTypes.bool.isRequired,
    practitioner: PropTypes.object,
    appointments: PropTypes.number,
    setDeleteLeaveId: PropTypes.func
};

export default withStyles(style)(WeekDay);
