import api from './api';

const query = () =>
    api.request({
        method: 'GET',
        url: '/schedule-templates'
    });

const get = id =>
    api.request({
        method: 'GET',
        url: `/schedule-templates/${id}`
    });

const create = data =>
    api.request({
        method: 'POST',
        url: '/schedule-templates',
        data
    });

const update = data =>
    api.request({
        method: 'PUT',
        url: '/schedule-templates',
        data
    });

const remove = id =>
    api.request({
        method: 'DELETE',
        url: `/schedule-templates/${id}`
    });

const removeMany = ids =>
    api.request({
        method: 'DELETE',
        url: '/schedule-templates',
        data: {
            ids
        }
    });

export default {
    query,
    get,
    create,
    update,
    remove,
    removeMany
};
