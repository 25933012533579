import React from 'react';

import { withStyles, Avatar, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import PropTypes from 'prop-types';

import { practitionerCardStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, practitionerCardStyle);

MomentDurationFormat(Moment);

const getInitials = name => {
    if (!name) return '';
    const initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

function Week({ classes, practitioner, renderLeaves = () => {}, renderShifts = () => {} }) {
    return (
        <td className={classNames(classes.practitionerCard)}>
            <div className={classNames(classes.practitionerCardBody)}>
                <div className={classNames(classes.row, classes.spaceBetween)}>
                    <div className={classNames(classes.column)}>
                        <div className={classNames(classes.row)}>
                            <Typography>{practitioner.displayName}</Typography>
                        </div>
                    </div>
                    <Avatar
                        className={classNames(classes.practitionerCardImage)}
                        alt={practitioner.displayName}
                        src={practitioner.avatar}
                    >
                        {getInitials(practitioner.displayName)}
                    </Avatar>
                </div>
                <div className={classNames(classes.topSpacing, classes.justifyCenter)}>
                    {/*<Typography>{`${shifts} shifts / ${totalHours}hrs`}</Typography>*/}
                    {renderShifts()}
                    {renderLeaves()}
                </div>
            </div>
        </td>
    );
}

Week.propTypes = {
    classes: PropTypes.object,
    practitioner: PropTypes.object,
    renderLeaves: PropTypes.func,
    renderShifts: PropTypes.func
};

export default withStyles(style)(Week);
