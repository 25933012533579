import Moment from 'moment';
import leaveTypes from '../constants/leaveTypes';

export const getShifts = (weekSchedules, dateFilter = null, weekLeaves = []) => {
    let relevantSchedules = weekSchedules;
    let relevantLeaves = weekLeaves;
    if (dateFilter !== null) {
        relevantSchedules = relevantSchedules.filter(schedule => {
            const date = Moment(schedule.date);
            return date.isSame(dateFilter, 'date');
        });
        relevantLeaves = relevantLeaves.filter(schedule => {
            const date = Moment(schedule.date);
            return date.isSame(dateFilter, 'date');
        });
    }
    const totalLeaveDuration = relevantLeaves.reduce(
        (acc, curr) =>
            acc.clone().add(Moment(curr.end, 'HH:mm').diff(Moment(curr.start, 'HH:mm'), 'minutes'), 'minutes'),
        Moment.duration(0)
    );
    const totalDuration = relevantSchedules.reduce(
        (acc, curr) =>
            acc.clone().add(Moment(curr.end, 'HH:mm').diff(Moment(curr.start, 'HH:mm'), 'minutes'), 'minutes'),
        Moment.duration(0)
    );
    const shifts = relevantSchedules.length;
    const label = shifts === 1 ? 'shift' : 'shifts';
    const duration = totalDuration.subtract(totalLeaveDuration).format('HH:mm', { trim: false });
    const shiftTime = `${shifts} ${label} / ${duration}h`;
    return shiftTime;
};

/**
 * return array of strings with leave times.
 * @param {array} weekLeaves array of leaveSchedules
 * @param {Moment} dateFilter the date to be filtered from the array
 */
export const getLeaves = (weekLeaves, dateFilter = null) => {
    let relevantSchedules = weekLeaves;
    if (dateFilter !== null) {
        relevantSchedules = relevantSchedules.filter(schedule => {
            const date = Moment(schedule.date);
            return date.isSame(dateFilter, 'date');
        });
    }
    const leaves = leaveTypes.map(type => {
        const filteredRelevantSchedules = relevantSchedules.filter(schedule => schedule.type === type.value);
        const shifts = filteredRelevantSchedules.length;
        if (shifts === 0) return null;
        const label = type.label;
        const totalDuration = filteredRelevantSchedules.reduce(
            (acc, curr) =>
                acc.clone().add(Moment(curr.end, 'HH:mm').diff(Moment(curr.start, 'HH:mm'), 'minutes'), 'minutes'),
            Moment.duration(0)
        );
        const duration = totalDuration.format('HH:mm', { trim: false });
        const leaveTime = `${shifts} ${label} / ${duration}h`;
        return leaveTime;
    });
    return leaves;
};
