import React, { useEffect, useState, useCallback } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { listClinics } from '../../api/ClinicApi';
import { isArray } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const Moment = require('moment');

export default function ClinicAvailability({ onClose }) {
    const [clinicOptions, setClinicOptions] = useState([]);

    const [selectedClinic, setSelectedClinic] = useState({});

    useEffect(() => {
        const getClinics = async () => {
            const clinics = await listClinics();
            setClinicOptions(clinics);
        };
        getClinics();
    }, []);

    const EmptyTable = ({ name }) => {
        return (
            <div
                style={{
                    width: '100%',
                    height: 150,
                    marginTop: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h3" style={{ textAlign: 'center' }}>
                    This clinic does not have {name} yet
                </Typography>
            </div>
        );
    };

    EmptyTable.propTypes = {
        name: PropTypes.string.isRequired
    };

    const BlankTable = ({ name }) => {
        return (
            <div
                style={{
                    width: '100%',
                    height: 150,
                    backgroundColor: '#c9c9c9',
                    marginTop: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography variant="h3" style={{ textAlign: 'center' }}>
                    Select one clinic to see the {name}
                </Typography>
            </div>
        );
    };

    BlankTable.propTypes = {
        name: PropTypes.string.isRequired
    };

    const Tables = ({ data, name }) => {
        return (
            <TableContainer style={{ marginTop: 30 }}>
                <Typography variant="h3">{name}</Typography>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography>
                                    <strong>Time</strong>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <strong>Start Time</strong>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <strong>End Time</strong>
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>
                                    <strong>Open</strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((element, index) => {
                            return (
                                <TableRow key={`${element.time}-${index}`}>
                                    <TableCell>
                                        <Typography>{element.time}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{element.startTime}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{element.endTime}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <FiberManualRecordIcon
                                            style={{ color: !element.isClosed ? '#29c358' : 'red' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    Tables.propTypes = {
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired
    };

    const Weeks = useCallback(() => {
        if (!selectedClinic.id) {
            return <BlankTable name="weeks" />;
        }
        if (!isArray(selectedClinic.time)) {
            return <EmptyTable name="weeks" />;
        }
        if (!selectedClinic.time.length) {
            return <EmptyTable name="weeks" />;
        }
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        const data = selectedClinic.time.map((element, index) => {
            const time = daysOfWeek[index];

            return {
                time,
                startTime: element.start,
                endTime: element.end,
                isClosed: element.isClosed
            };
        });
        return <Tables name="Weeks" data={data} />;
    }, [selectedClinic]);

    const Holidays = useCallback(() => {
        if (!selectedClinic.id) {
            return <BlankTable name="holidays" />;
        }
        if (!isArray(selectedClinic.holidays)) {
            return <EmptyTable name="holidays" />;
        }
        if (!selectedClinic.holidays.length) {
            return <EmptyTable name="holidays" />;
        }
        const data = selectedClinic.holidays.map(element => {
            const time = Moment(element.date).format('DD/MM/YY');

            return {
                time,
                startTime: element.start,
                endTime: element.end,
                isClosed: element.isClosed
            };
        });
        return <Tables name="Holidays" data={data} />;
    }, [selectedClinic]);

    return (
        <Dialog open={true} onClose={onClose}>
            <IconButton onClick={onClose} style={{ position: 'absolute', right: 0, top: 0 }}>
                <CloseIcon />
            </IconButton>
            <DialogTitle>Clinic Availability</DialogTitle>
            <DialogContent>
                <div
                    style={{
                        outline: 'none',
                        padding: 10
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            outline: 'none'
                        }}
                    >
                        <Autocomplete
                            id="clinic-selector"
                            options={clinicOptions}
                            getOptionLabel={option => option.accountName}
                            style={{ marginTop: 30 }}
                            renderInput={params => <TextField {...params} label="Clinic" variant="outlined" />}
                            onChange={(e, newValue) => {
                                setSelectedClinic(newValue || {});
                            }}
                        />
                        {Weeks()}
                        {Holidays()}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

ClinicAvailability.propTypes = {
    onClose: PropTypes.func.isRequired
};
