import React, { useEffect, useState } from 'react';

import { withStyles, Typography, Modal, Paper, IconButton, TextField, Checkbox, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DateInput from '../common/dateInput';
import SelectPractitionerInput from '../common/selectPractitionerInput';
import NewLeaveButton from './newLeaveButton';
import DeleteLeaveModal from './deleteLeaveModal';

import { Close } from '../../assets/icons';
import LEAVE_TYPES from '../../constants/leaveTypes';

import { sharedStyle, newLeaveStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import InfoModal from './infoModal';
import practitionerScheduleApi from '../../api/practitionerScheduleApi';

const style = mergeStyles(commonStyle, sharedStyle, newLeaveStyle);

MomentDurationFormat(Moment);

const TIMES = Array((24 - 6) * 4 + 1)
    .fill(Moment.utc('06:00', 'HH:mm'))
    .map((item, index) => item.clone().add(15 * index, 'minutes'));
const TIMEOPTIONS = [...TIMES.map(time => time.format('HH:mm'))];

function NewLeave({ classes, leaveData, setLeaveData, reloadAllData = () => {} }) {
    const [deleteLeaveModalIsOpen, setDeleteLeaveModalIsOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [practitionerSchedules, setPractitionerSchedules] = useState([]);

    const openDeleteLeaveModal = () => setDeleteLeaveModalIsOpen(true);

    const closeDeleteLeaveModal = () => setDeleteLeaveModalIsOpen(false);

    const onClose = () => setLeaveData(leaveData => ({ ...leaveData, open: false, id: undefined }));

    const loadPractitionerSchedules = async () => {
        // console.log('leaveData', leaveData);
        const location = [leaveData.locations?._id || leaveData.locations?.id || leaveData.clinic];
        const practitioners = [leaveData.practitioner?._id || leaveData.practitioner?.id];
        const dayFrom = typeof leaveData.startDate === 'string'? leaveData.startDate : leaveData.startDate.format('YYYY-MM-DD');
        const dayTo = typeof leaveData.endDate === 'string'? leaveData.endDate : leaveData.endDate.format('YYYY-MM-DD');

        if (location[0] && practitioners[0] && dayFrom && dayTo) {
            const practitionerSchedulesData = await practitionerScheduleApi.query({
                location,
                practitioners,
                dayFrom,
                dayTo
            });
            setPractitionerSchedules(practitionerSchedulesData.find(schedule => schedule.practitioner.id === practitioners[0]));
        } else {
            setPractitionerSchedules([]);
        }
    };
    useEffect(() => {
        (async () => await loadPractitionerSchedules())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leaveData]);

    const setPractitioner = value => {
        setLeaveData(leaveData => ({ ...leaveData, practitioner: value }));

    };
    const setType = (evt, value) => {
        setLeaveData(leaveData => ({ ...leaveData, type: value }));
    };
    const setPaid = evt => {
        const value = evt.target.checked;
        setLeaveData(leaveData => ({ ...leaveData, paid: value }));
    };
    const setAllDay = evt => {
        const value = evt.target.checked;
        setLeaveData(leaveData => ({ ...leaveData, allDay: value }));
    };
    const setStartDate = value => {
        setLeaveData(leaveData => ({ ...leaveData, startDate: value }));
    };
    const setStartTime = (evt, value) => {
        setLeaveData(leaveData => ({ ...leaveData, startTime: value }));
    };
    const setEndDate = value => {
        setLeaveData(leaveData => ({ ...leaveData, endDate: value }));
    };
    const setEndTime = (evt, value) => {
        setLeaveData(leaveData => ({ ...leaveData, endTime: value }));
    };
    const getLeaveDuration = () => {
        // console.log('!!!getLeaveDuration', practitionerSchedules);
        const allDay = leaveData.allDay;
        const startDate = Moment(leaveData.startDate, 'YYYY-MM-DD');
        const endDate = Moment(leaveData.endDate, 'YYYY-MM-DD');
        // todo: make clinic variables fetch from db
        let currentDate = Moment(startDate);
        let duration = Moment.duration({ hours: 0, minutes: 0 });
        let days = 0;
        while (currentDate <= Moment(endDate)) {
            const date = currentDate.format('YYYY-MM-DD');
            const currentSchedule = practitionerSchedules?.schedules?.find(schedule => schedule.date.substring(0, 10) === date);
            if (currentSchedule) {
                const leaveStart = allDay ? currentSchedule.start : (currentSchedule.start > leaveData.startTime ? currentSchedule.start : leaveData.startTime);
                const leaveEnd = allDay ? currentSchedule.end : (currentSchedule.end < leaveData.endTime ? leaveData.endTime.end : leaveData.endTime);
                const currentDuration = Moment.duration(Moment(leaveEnd, 'HH:mm').diff(Moment(leaveStart, 'HH:mm')));
                duration = duration.add(currentDuration);
                days++;
            }

            currentDate = currentDate.add(1, 'day');
        }
        return `${(duration.days() * 24 + duration.hours())} hrs ${duration.minutes() ? duration.minutes() + ' m' : ''} / ${days} days`;
    };

    const openInfoModalHandler = () => {
        setIsInfoModalOpen(true);
    };

    return (
        <>
            <Modal open={leaveData.open} onClose={onClose} className={classes.modalContainer}>
                <Paper className={classNames(classes.modalPaper, classes.leaveModal, classes.copyModal)}>
                    <div className={classNames(classes.row, classes.alignCenter)}>
                        <div className={classNames(classes.row, classes.grow, classes.justifyCenter)}>
                            <Typography className={classes.modalTitle} style={{ fontSize: 18 }}>
                                {leaveData.id ? 'Edit Leave' : 'Book Leave'}
                            </Typography>
                        </div>
                        <div className={classes.closeModal}>
                            <IconButton size="small" onClick={onClose}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                    <table className={classes.newLeaveTableForm}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography>Staff:</Typography>
                                </td>
                                <td />
                                <td>
                                    <Typography>Type:</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectPractitionerInput
                                        className={classes.selectPractitionerInput}
                                        onSelect={setPractitioner}
                                        value={leaveData.practitioner}
                                        fromLeaveModal={true}
                                    />
                                </td>
                                <td />
                                <td>
                                    <Autocomplete
                                        className={classes.noTopMargin}
                                        autoComplete
                                        autoHighlight
                                        options={LEAVE_TYPES}
                                        getOptionLabel={option => option.label}
                                        value={leaveData.type}
                                        onChange={setType}
                                        renderInput={params => (
                                            <TextField margin="dense" variant="outlined" {...params} />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>{!leaveData.id ? 'Start date':'Date'}:</Typography>
                                </td>
                                <td />
                                <td>
                                    <Typography>Start time:</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <DateInput value={leaveData.startDate} onChange={setStartDate} />
                                </td>
                                <td />
                                <td>
                                    <Autocomplete
                                        className={classes.noTopMargin}
                                        autoComplete
                                        disabled={leaveData.allDay}
                                        autoHighlight
                                        options={TIMEOPTIONS}
                                        value={leaveData.startTime}
                                        onChange={setStartTime}
                                        renderInput={params => (
                                            <TextField margin="dense" variant="outlined" {...params} />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {!leaveData.id && <Typography>End date:</Typography>}
                                </td>
                                <td />
                                <td>
                                    <Typography>End time:</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {!leaveData.id && <DateInput value={leaveData.endDate} onChange={setEndDate} />}
                                </td>
                                <td />
                                <td>
                                    <Autocomplete
                                        className={classes.noTopMargin}
                                        autoComplete
                                        disabled={leaveData.allDay}
                                        autoHighlight
                                        options={TIMEOPTIONS}
                                        value={leaveData.endTime}
                                        onChange={setEndTime}
                                        renderInput={params => (
                                            <TextField margin="dense" variant="outlined" {...params} />
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr></tr>
                            <tr></tr>
                            <tr>
                                <td>
                                    <Typography>Duration:</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography>{getLeaveDuration()}</Typography>
                                </td>
                            </tr>
                            <tr></tr>
                            <tr></tr>

                            <td>
                                <div className={classNames(classes.row, classes.alignCenter)}>
                                    <Typography>Paid:</Typography>
                                    <Checkbox
                                        className={classes.noVerticalPadding}
                                        checked={leaveData.paid}
                                        onChange={setPaid}
                                    />
                                    <Typography className={classes.nowrapText}>All day:</Typography>
                                    <Checkbox
                                        className={classes.noVerticalPadding}
                                        checked={leaveData.allDay}
                                        onChange={setAllDay}
                                    />
                                </div>
                            </td>
                            <tr></tr>
                        </tbody>
                    </table>
                    <div
                        className={classNames(
                            classes.row,
                            classes.justifyCenter,
                            classes.topSpacing,
                            classes.itemSpacingContainer
                        )}
                    >
                        <Button
                            className={classNames(classes.button, classes.cancelButton)}
                            onClick={onClose}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        {leaveData.id && (
                            <Button
                                className={classNames(classes.button, classes.confirmButton)}
                                onClick={openDeleteLeaveModal}
                                variant="contained"
                            >
                                Delete
                            </Button>
                        )}
                        <NewLeaveButton leaveData={leaveData} onClose={onClose} openInfoModal={openInfoModalHandler} reloadAllData={reloadAllData} />
                    </div>
                </Paper>
            </Modal>
            <DeleteLeaveModal
                onClose={closeDeleteLeaveModal}
                closeLeaveModal={onClose}
                leaveData={leaveData}
                open={deleteLeaveModalIsOpen}
                reloadAllData={reloadAllData}
            />
            <InfoModal
                open={isInfoModalOpen}
                onClose={() => setIsInfoModalOpen(false)}
                message="Please create a schedule in the schedule module before booking leave"
                title="Unable to book leave"
            />
        </>
    );
}

NewLeave.propTypes = {
    classes: PropTypes.object.isRequired,
    leaveData: PropTypes.object.isRequired,
    setLeaveData: PropTypes.func.isRequired,
    reloadAllData: PropTypes.func
};

export default withStyles(style)(NewLeave);
