import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './style/index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import Routes from './routes';
import configureStore from './store/configureStore';
import { AccessTierProvider } from './collums-components/hooks/accessTier';
import moment from 'moment-timezone';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { initSentry } from './collums-components/helpers/sentry';

initSentry();
moment.tz.setDefault(process.env.REACT_APP_TIMEZONE || 'Europe/London');

ReactDOM.render(
    <Provider store={configureStore()}>
        <BrowserRouter>
            <AccessTierProvider>
                <App>
                    <Routes />
                </App>
            </AccessTierProvider>
        </BrowserRouter>
        <ReduxToastr
            timeOut={3000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-left"
            getState={state => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
        />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
