const defaultWeekTemplate = [
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    },
    {
        working: false,
        start: '09:00',
        end: '17:00'
    }
];

export default defaultWeekTemplate;
