import React, { useEffect, useState } from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enGbLocale from 'date-fns/locale/en-GB/index';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import '../style/App.css';
import Header from './header/index';
import SideBar from '../collums-components/components/common/Sidebar';
import { validateJobPermissions } from '../collums-components/helpers/index';
import AuthApi from '../api/authApi';
import theme from './theme';
import { usePromiseTracker } from 'react-promise-tracker';
import Spinner from './spinner/index';
import api from '../api/api';
import scheduleTemplateApi from '../api/scheduleTemplateApi';
import { getQueryParams } from '../services/locationHelper';
import config from '../config';
import ShowPINModal from './../collums-components/components/common/PINModal/index';
import { COLLUMS_APPS_ACCESS_TIER } from '../collums-constants';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import { isAvailableByPlan } from '../collums-constants/utils';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';
import GenericErrorBoundaryFallback from '../collums-components/components/common/GenericErrorBoundaryFallback';
import { setSentryUser } from '../collums-components/helpers/sentry';
import LogoutModal from '../collums-components/components/common/LogoutModal';
import Intercom from '../collums-components/helpers/Intercom';
import { validateAndUpdateClinic } from '../collums-components/helpers/user';

const LoadingSpinnerComponent = () => {
    const { promiseInProgress } = usePromiseTracker();

    return <Spinner display={promiseInProgress} />;
};

function App(props) {
    const location = useLocation();
    const [isInitialized, setIsInitialized] = useState(false);
    const [sentryDialogOpts, setSentryDialogOpts] = useState({});
    const [cookies] = useCookies(['token']);

    useEffect(() => {
        const doEffect = async () => {
            const token = getQueryParams(location).token || cookies.token;
            if (token) {
                try {
                    api.config({ headers: { Authorization: token } });
                    localStorage.setItem('token', token);
                    const me = await AuthApi.getMe();
                    setSentryDialogOpts(setSentryUser(me));
                    await validateJobPermissions(token, me, process.env.REACT_APP_NAME);
                    await validateAndUpdateClinic(
                        me,
                        getQueryParams(location).clinic || localStorage.getItem('currentClinic')
                    );
                    await scheduleTemplateApi.query();
                    await Intercom.updateUser(me);
                } catch (error) {
                    if (error && error.status === 401) {
                        window.location = `${config.authUrl}?redirect=employees`;
                    } else {
                        throw error;
                    }
                }
            } else {
                window.location = `${config.authUrl}?redirect=employees`;
            }
            setIsInitialized(true);
        };

        doEffect();
        //eslint-disable-next-line
    }, []);

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.SCHEDULES)) {
        return <EnvironmentDisabled />;
    }

    if (!isInitialized) {
        return null;
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <div className="container-fluid">
                <div className="App">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGbLocale}>
                        <MuiThemeProvider theme={theme}>
                            <ShowPINModal
                                onEnterPin={(user, token) => {
                                    api.config({ headers: { Authorization: token } });
                                }}
                            />
                            {cookies.logoutActive === 'true' && <LogoutModal />}
                            <>
                                <div className="Page-view">
                                    {location.pathname !== '/login' && <Header />}
                                    <Sentry.ErrorBoundary
                                        fallback={<GenericErrorBoundaryFallback />}
                                        showDialog
                                        dialogOptions={sentryDialogOpts}
                                    >
                                        <main className="Page-view">{props.children}</main>
                                    </Sentry.ErrorBoundary>
                                </div>
                                {location.pathname !== '/login' && (
                                    <div>
                                        <SideBar appName="schedules" />
                                    </div>
                                )}
                                <LoadingSpinnerComponent />
                            </>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </div>
            </div>
        </React.Fragment>
    );
}

App.propTypes = {
    children: PropTypes.object.isRequired
};

export default App;
