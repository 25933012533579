import React from 'react';

import { Button, withStyles } from '@material-ui/core';
import Moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toastr } from 'react-redux-toastr';

import practitionerLeaveApi from '../../api/practitionerLeaveApi';

import { commonStyle, mergeStyles } from '../../style/common';
import { sharedStyle } from './style';
const style = mergeStyles(commonStyle, sharedStyle);

// eslint-disable-next-line no-unused-vars
const newLeaveButton = ({ classes, leaveData, onClose, openInfoModal, reloadAllData = () => {} }) => {
    const newLeave = async () => {
        if (!leaveData.practitioner) {
            toastr.warning('Select a Staff');
        } else if (!leaveData.startDate) {
            toastr.warning('Select a Start date');
        } else if (!leaveData.endDate) {
            toastr.warning('Select an End date');
        } else if (!leaveData.type) {
            toastr.warning('Select a Leave type');
        } else if (!leaveData.allDay && !leaveData.startTime) {
            toastr.warning('Select a Start time or check the All day option');
        } else if (!leaveData.allDay && !leaveData.endTime) {
            toastr.warning('Select an End time or check the All day option');
        } else if (Moment(leaveData.endDate, 'YYYY-MM-DD').isBefore(Moment(leaveData.startDate, 'YYYY-MM-DD'))) {
            toastr.warning('End date must be same or after Start date');
        } else if (
            !leaveData.allDay &&
            Moment(leaveData.endTime, 'HH:mm').isSameOrBefore(Moment(leaveData.startTime, 'HH:mm'))
        ) {
            toastr.warning('End time must be after Start time');
        } else {
            const leaveDataRequest = {
                practitioner: leaveData.practitioner._id || leaveData.practitioner.id,
                startDate: typeof leaveData.startDate === 'string'? leaveData.startDate : leaveData.startDate.format('YYYY-MM-DD'),
                endDate: typeof leaveData.endDate === 'string'? leaveData.endDate : leaveData.endDate.format('YYYY-MM-DD'),
                clinic: leaveData.locations?._id || leaveData.locations?.id || leaveData.clinic,
                type: leaveData.type.value,
                start: leaveData.startTime,
                end: leaveData.endTime,
                allDay: leaveData.allDay,
                multipleDays: true // this flag is required to be true coming from employees
            };
            if (leaveData.id) {
                if (leaveDataRequest.startDate) {
                    leaveDataRequest.date = leaveDataRequest.startDate;
                    delete leaveDataRequest.startDate;
                }
                if (leaveDataRequest.endDate) delete leaveDataRequest.endDate;
                if (leaveDataRequest.multipleDays !== undefined) delete leaveDataRequest.multipleDays;
                if (leaveDataRequest.allDay === undefined) delete leaveDataRequest.allDay;

                try {
                    await practitionerLeaveApi.update(leaveData.id, leaveDataRequest);
                    reloadAllData();
                    toastr.success('Leave updated');
                } catch (err) {
                    toastr.error(err?.data?.message || 'Something went wrong');
                    return;
                }
            } else {
                try {
                    const leaveCreated = await practitionerLeaveApi.create(leaveDataRequest);
                    if (leaveCreated.id) {
                        reloadAllData();
                        toastr.success('Leave created');
                    } else {
                        openInfoModal();
                    }
                } catch (err) {
                    toastr.error(err?.data?.message || 'Something went wrong');
                    return;
                }
            }
            onClose();
        }
    };

    return (
        <Button className={classNames(classes.button, classes.saveButton)} variant="contained" onClick={newLeave}>
            Save
        </Button>
    );
};

newLeaveButton.propTypes = {
    leaveData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    openInfoModal: PropTypes.func.isRequired,
    reloadAllData: PropTypes.func
};

export default withStyles(style)(newLeaveButton);
