import React from 'react';

import { withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { calendarHeaderStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, calendarHeaderStyle);

function CalendarHeader({ classes, cells }) {
    return (
        <thead>
            <tr>
                {cells.map((cell, index) => (
                    <td key={index} style={cell.style} className={classNames(classes.calendarHeader)}>
                        <Typography>{cell.content}</Typography>
                    </td>
                ))}
            </tr>
        </thead>
    );
}

CalendarHeader.propTypes = {
    classes: PropTypes.object,
    cells: PropTypes.array
};

export default withStyles(style)(CalendarHeader);
