import React from 'react';
import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classNames from 'classnames';

import { dateInputStyles } from './style';

const DateInput = ({ value, onChange, disabled, className, classes }) => {
    const handleDateChange = event => {
        const date = event.target
            ? Moment(event.target.value, 'DD/MM/YY').format('YYYY-MM-DD')
            : Moment(event).format('YYYY-MM-DD');
        onChange(date);
    };

    const initialAppointmentDate = Moment(value, 'YYYY-MM-DD').toDate();

    const datePickerInputRef = React.useRef();

    const formatDateWithAppend = string => {
        //in case user input using tab the string param will recieve only the letter inputed.
        if (string.length === 1) {
            let newString = Moment(value, 'YYYY-MM-DD').format('DD/MM/YY');
            newString
                .split('')
                .splice(0, 1, string)
                .join('');
            return newString;
        }

        const inputPosition = datePickerInputRef.current.selectionStart;
        const cleanString = string.replace(/\//g, '');
        const addedString = string.charAt(inputPosition - 1);
        const cleanInputPosition = (() => {
            if (inputPosition > 6) return inputPosition - 2;
            if (inputPosition > 3) return inputPosition - 1;
            return inputPosition;
        })();
        const previousString =
            cleanString.substring(0, cleanInputPosition) +
            cleanString.substring(cleanInputPosition + 1, cleanString.length);
        let newString = previousString;
        newString
            .split('')
            .splice(cleanInputPosition, 1, addedString)
            .join('');

        return newString[0] + newString[1] + '/' + newString[2] + newString[3] + '/' + newString[4] + newString[5];
    };

    const handleClearDatePickerKeyboard = event => {
        //backspace(8) or delete(46) key
        if (event.keyCode === 8 || event.keyCode === 46) {
            if (datePickerInputRef.current.createTextRange) {
                var part = datePickerInputRef.current.createTextRange();
                part.move('character', 0);
                part.select();
            } else if (datePickerInputRef.current.setSelectionRange) {
                datePickerInputRef.current.setSelectionRange(0, 0);
            }
            datePickerInputRef.current.focus();
            handleDateChange(initialAppointmentDate);
        }
    };

    return (
        <KeyboardDatePicker
            className={classNames(classes.root, className)}
            autoOk
            disableToolbar
            rifmFormatter={formatDateWithAppend}
            inputProps={{
                ref: datePickerInputRef,
                onKeyDown: handleClearDatePickerKeyboard
            }}
            variant="inline"
            format="dd/MM/yy"
            mask="__/__/__"
            maskChar={'_'}
            inputVariant="outlined"
            value={Moment(value, 'YYYY-MM-DD').toDate()}
            disabled={disabled}
            onAccept={handleDateChange}
            onBlur={handleDateChange}
            onChange={() => {}}
        />
    );
};

DateInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired
};

export default withStyles(dateInputStyles)(DateInput);
