import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
        left: 'calc(50% - 20px)',
        top: 'calc(50% - 20px)',
        position: 'fixed',
        zIndex: 1500
    }
});

function Spinner(props) {
    const { classes } = props;
    return <div>{props.display ? <CircularProgress className={classes.progress} color="secondary" /> : ''}</div>;
}

Spinner.propTypes = {
    classes: PropTypes.object.isRequired,
    display: PropTypes.bool
};

export default withStyles(styles)(Spinner);
