import React from 'react';

import { withStyles, Typography, Button, Modal, Paper, Switch } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import { WEEK_DAYS } from '../../collums-constants/index';
const style = mergeStyles(commonStyle, sharedStyle);

function CopyDayModal({ classes, copyDayData, setCopyDayData, copyDay, form }) {
    const onClose = () => setCopyDayData(copyDayData => ({ ...copyDayData, open: false }));

    const onToggleAll = evt => {
        const newValue = evt.target.checked;
        const dayIndexTo = copyDayData.dayIndexTo.map((e, i) => (i === copyDayData.dayIndexFrom ? true : newValue));
        setCopyDayData(copyDayData => ({ ...copyDayData, dayIndexTo }));
    };
    const onToggle = (evt, index) => {
        const newValue = evt.target.checked;
        const dayIndexTo = copyDayData.dayIndexTo.map((e, i) => (i === index ? newValue : e));
        setCopyDayData(copyDayData => ({ ...copyDayData, dayIndexTo }));
    };

    const { open, weekIndexFrom, dayIndexFrom, dayIndexTo } = copyDayData;
    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.copyModal)}>
                <div className={classNames(classes.row, classes.justifyCenter)}>
                    <Typography
                        className={classes.modalTitle}
                    >{`Copy ${WEEK_DAYS[dayIndexFrom]}'s schedule to:`}</Typography>
                </div>
                <div className={classes.column}>
                    <div className={classNames(classes.row, classes.alignCenter, classes.spaceBetween)}>
                        <Typography>Select/Clear all</Typography>
                        <Switch checked={dayIndexTo.every(e => e)} onClick={onToggleAll} />
                    </div>
                    {form.weeks[weekIndexFrom].map((day, index) => (
                        <div className={classNames(classes.row, classes.alignCenter, classes.spaceBetween)} key={index}>
                            <Typography>{WEEK_DAYS[index]}</Typography>
                            <Switch
                                checked={dayIndexTo[index]}
                                disabled={index === dayIndexFrom}
                                onClick={evt => onToggle(evt, index)}
                            />
                        </div>
                    ))}
                </div>
                <div className={classNames(classes.row, classes.justifyCenter, classes.itemSpacingContainer)}>
                    <Button
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classNames(classes.button, classes.confirmButton)}
                        variant="contained"
                        onClick={copyDay}
                    >
                        Copy
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
}

CopyDayModal.propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    copyDayData: PropTypes.object.isRequired,
    setCopyDayData: PropTypes.func.isRequired,
    copyDay: PropTypes.func.isRequired
};

export default withStyles(style)(CopyDayModal);
