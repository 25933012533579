import React, { useState } from 'react';

import { TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import scheduleTemplateApi from '../../api/scheduleTemplateApi';

import { selectTemplateInputStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
const style = mergeStyles(commonStyle, selectTemplateInputStyle);

const SelectTemplateInput = ({ options, onSelect }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const selectTemplate = async template => {
        setSelectedTemplate(template);
        if (template) {
            const result = await scheduleTemplateApi.get(template.id);
            result.weeks = result.weeks.map(week =>
                week.map(day => {
                    // eslint-disable-next-line no-unused-vars
                    const { id, ...newDay } = day;
                    return newDay;
                })
            );
            onSelect(result);
        }
    };

    return (
        <Autocomplete
            style={{ width: '33%' }}
            autoComplete
            autoHighlight
            options={options}
            value={selectedTemplate}
            onChange={(event, value) => selectTemplate(value)}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} variant="outlined" label="Template" />}
        />
    );
};

SelectTemplateInput.propTypes = {
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(style)(SelectTemplateInput);
