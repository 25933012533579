import React from 'react';

import { withStyles, Typography, Modal, Paper, IconButton, Link } from '@material-ui/core';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import practitionerLeaveApi from '../../api/practitionerLeaveApi';
import LEAVE_TYPES from '../../constants/leaveTypes';

import { Close } from '../../assets/icons';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import getWhiteOrBlackColor from '../../services/getWhiteOrBlackColor';
const style = mergeStyles(commonStyle, sharedStyle);

MomentDurationFormat(Moment);

function dayPopupModal({ classes, dayPopupData, setDayPopupData, setLeaveData }) {
    const onClose = () => setDayPopupData(dayPopupData => ({ ...dayPopupData, open: false }));

    const getLongTitle = event => {
        const { title } = event;
        const splitTitle = title.split(' ');
        // get the start and end times from the title
        const times = splitTitle[0].split('-');
        const start = Moment(times[0], 'HH:mm');
        const end = Moment(times[1], 'HH:mm');
        // calculate duration
        const durationInMinutes = end.diff(start, 'minutes');
        const duration = Moment.duration(durationInMinutes, 'minutes');
        const durationText = duration.format('HH:mm', { trim: false });
        // add the 'durationText' between 'start-end' and 'practitioner'
        splitTitle.splice(1, 0, durationText);
        const newTitle = splitTitle.join(' ');
        return newTitle;
    };

    const getEventStyle = event => {
        const { backgroundColor, borderColor } = event;
        const style = {
            backgroundColor,
            border: `1px solid ${borderColor}`,
            color: getWhiteOrBlackColor(backgroundColor)
        };
        return style;
    };

    const openLeaveModal = async id => {
        const leaveData = await practitionerLeaveApi.query(id);
        const { practitioner, type, isPaid, locations, startDate, endDate, start, end } = leaveData;
        const leaveForm = {
            id,
            practitioner: practitioner,
            type: LEAVE_TYPES.filter(e => e.value === type)[0],
            isPaid,
            allDay: false, // this is just a flag sent to backend, it's value isn't saved
            locations,
            startDate,
            endDate,
            startTime: start,
            endTime: end
        };
        setLeaveData(data => ({ ...data, open: true, ...leaveForm }));
        onClose();
    };

    const { open, date, events } = dayPopupData;

    return (
        <Modal open={open} onClose={onClose} className={classes.modalContainer}>
            <Paper className={classNames(classes.modalPaper, classes.copyModal)}>
                <div className={classNames(classes.row, classes.alignCenter)}>
                    <div className={classNames(classes.row, classes.grow, classes.justifyCenter)}>
                        <Typography className={classes.modalTitle}>{date.format('dddd Do MMMM YYYY')}</Typography>
                    </div>
                    <div className={classes.closeModal}>
                        <IconButton size="small" onClick={onClose}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
                <div className={classNames(classes.column)}>
                    {events.map((event, index) => (
                        <div
                            onClick={() => openLeaveModal(event.id)}
                            style={getEventStyle(event)}
                            className={classNames(classes.row, classes.justifyCenter, classes.eventContainer)}
                            key={index}
                        >
                            <Typography className={classes.centerLabel}>{getLongTitle(event)}</Typography>
                        </div>
                    ))}
                </div>
                <div className={classNames(classes.row, classes.justifyCenter, classes.topSpacing)}>
                    <Link href={`/schedule/edit?date=${date.format('YYYY-MM-DD')}`}>
                        <Typography>Edit schedules</Typography>
                    </Link>
                </div>
            </Paper>
        </Modal>
    );
}

dayPopupModal.propTypes = {
    classes: PropTypes.object.isRequired,
    dayPopupData: PropTypes.object.isRequired,
    setDayPopupData: PropTypes.func.isRequired,
    setLeaveData: PropTypes.func.isRequired
};

export default withStyles(style)(dayPopupModal);
