import React from 'react';

import { withStyles, Typography, IconButton, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import Moment from 'moment';
import classNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import practitionerLeaveApi from '../../api/practitionerLeaveApi';
import { ChevronBack, ChevronForward } from '../../assets/icons';
import ViewPractitionerWeekSchedule from './viewPractitionerWeekSchedule';
import CalendarHeader from '../common/calendarHeader';
import WeekResume from '../common/weekResume';
import NavigationCalendarButton from '../common/navigationCalendarButton';
import LEAVE_TYPES from '../../constants/leaveTypes';

import { sharedStyle, weekViewStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import getWhiteOrBlackColor from '../../services/getWhiteOrBlackColor';
const style = mergeStyles(commonStyle, sharedStyle, weekViewStyle);

const leaveLayouts = [
    {
        backgroundColor: '#f94940',
        borderColor: '#f94940',
        textColor: '#000000'
    }
];

function WeekView({
    classes,
    practitionerSchedules,
    filterByInactives,
    setFilterByInactives,
    practitionerFilters,
    practitionerLeaves,
    setDayPopupData,
    setLeaveData,
    onDateChange
}) {
    const [startOfWeek, setStartOfWeek] = React.useState(
        window.location.search === ''
            ? moment().startOf('isoWeek')
            : moment(window.location.search.split(/[%&=]/)[1], 'DD-MM-YYYY').startOf('isoWeek')
    );
    const [showLocation, setShowLocation] = React.useState(true);

    React.useEffect(() => {
        onDateChange(startOfWeek);
        // eslint-disable-next-line
    }, [startOfWeek]);

    const handleShowLocationChange = () => {
        setShowLocation(value => !value);
    };

    const handlePreviousWeek = () => {
        const previousWeek = startOfWeek.clone().subtract(1, 'week');
        setStartOfWeek(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = startOfWeek.clone().add(1, 'week');
        setStartOfWeek(nextWeek);
    };

    const handleDateChange = date => {
        setStartOfWeek(Moment(date).startOf('isoWeek'));
    };

    const handleToday = () => {
        setStartOfWeek(moment().startOf('isoWeek'));
    };

    const openDayPopup = (event, date) => {
        event.preventDefault();
        event.stopPropagation();
        const events = [
            ...practitionerSchedules.flatMap(schedules => {
                return schedules.schedules
                    .filter(schedule => date.isSame(Moment(schedule.date), 'date'))
                    .map(schedule => {
                        const { start, end, id } = schedule;
                        const { practitioner } = schedules;
                        const eventLayout = {
                            backgroundColor: schedules.practitioner.colour || '#000000',
                            borderColor: schedules.practitioner.colour
                                ? getWhiteOrBlackColor(schedules.practitioner.colour)
                                : '#fff',
                            textColor: schedules.practitioner.colour
                                ? getWhiteOrBlackColor(schedules.practitioner.colour)
                                : '#fff'
                        };
                        const title = `${start}-${end} ${practitioner.displayName}`;
                        return { ...eventLayout, title, id };
                    });
            }),
            ...practitionerLeaves.flatMap((leaves, practitionerIndex) => {
                return leaves.leaves
                    .filter(schedule => date.isSame(Moment(schedule.date), 'date'))
                    .map(schedule => {
                        const { start, end, id } = schedule;
                        const { practitioner } = leaves;
                        const eventLayout = leaveLayouts[practitionerIndex % leaveLayouts.length];
                        const title = `${start}-${end} ${practitioner.displayName}`;
                        return { ...eventLayout, title, id };
                    });
            })
        ];
        setDayPopupData(dayPopupData => ({ ...dayPopupData, open: true, date, events }));
    };

    const openLeaveModal = async (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const leaveData = await practitionerLeaveApi.query(id);
        const { practitioner, type, isPaid, locations, date, start, end, clinic } = leaveData;
        const leaveForm = {
            id,
            practitioner: practitioner,
            type: LEAVE_TYPES.filter(e => e.value === type)[0],
            isPaid,
            allDay: false, // this is just a flag sent to backend, it's value isn't saved
            locations,
            startDate: Moment(date, 'YYYY-MM-DD'),
            endDate: Moment(date, 'YYYY-MM-DD'),
            startTime: start,
            endTime: end,
            clinic
        };
        setLeaveData(data => ({ ...data, open: true, ...leaveForm }));
    };

    const endOfWeek = startOfWeek.clone().endOf('isoWeek');

    const startOfWeekString = startOfWeek.format('DD/MM/YYYY');
    const endOfWeekString = endOfWeek.format('DD/MM/YYYY');

    const week = Array(7)
        .fill(startOfWeek.clone())
        .map((e, i) => e.clone().add(i, 'days'));
    const cells = [
        { content: '', style: { width: 164 } },
        ...week.map(e => ({ content: e.format('ddd DD/MM/YY'), style: { textAlign: 'center' } }))
    ];

    let practitioners = [...practitionerSchedules, ...practitionerLeaves].map(e => e.practitioner);
    practitioners = practitioners.reduce(
        (acc, curr) => (acc.filter(a => a.id === curr.id).length ? acc : [...acc, curr]),
        []
    );

    return (
        <>
            {/* Week navigation */}
            <div className={classNames(classes.row, classes.alignCenter)}>
                <div />
                <div
                    className={classNames(
                        classes.row,
                        classes.itemSpacingContainer,
                        classes.alignCenter,
                        classes.navigationWeek
                    )}
                >
                    <IconButton onClick={handlePreviousWeek}>
                        <ChevronBack variant="medium" />
                    </IconButton>
                    <div className={classNames(classes.row)}>
                        <Button
                            className={classNames(classes.capitalize, classes.todayButton)}
                            style={{ textAlign: 'center' }}
                            onClick={handleToday}
                            variant="outlined"
                        >
                            Today
                        </Button>

                        <Typography
                            className={classNames(classes.weekCalendarHeaderDate, classes.topSpacing)}
                            style={{ textAlign: 'right' }}
                        >
                            {`${startOfWeekString} - ${endOfWeekString}`}
                        </Typography>
                    </div>
                    <IconButton onClick={handleNextWeek}>
                        <ChevronForward variant="medium" />
                    </IconButton>
                    <NavigationCalendarButton onChange={handleDateChange} value={startOfWeek} variant="medium" />
                </div>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showLocation}
                                onChange={handleShowLocationChange}
                                name="showLocationCB"
                            />
                        }
                        label="Show location"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterByInactives}
                                onChange={() => setFilterByInactives(!filterByInactives)}
                            />
                        }
                        label="Show inactive staff"
                    />
                </div>
            </div>
            <table className={classNames(classes.calendarTable)}>
                <CalendarHeader cells={cells} />
                <tbody>
                    {practitioners
                        .filter(e => {
                            if (!practitionerFilters.length) return true;
                            const filter = practitionerFilters.map(e => {
                                return e.id;
                            });
                            return filter.includes(e.id);
                        })
                        .map((practitioner, practitionerIndex) => {
                            const practitionerSchedule = practitionerSchedules.find(
                                e => e.practitioner.id === practitioner.id
                            );
                            const practitionerLeave = practitionerLeaves.find(
                                e => e.practitioner.id === practitioner.id
                            );
                            return (
                                <ViewPractitionerWeekSchedule
                                    key={practitionerIndex}
                                    practitioner={practitioner}
                                    schedules={practitionerSchedule ? practitionerSchedule.schedules : []}
                                    leaves={practitionerLeave ? practitionerLeave.leaves : []}
                                    practitionerIndex={practitionerIndex}
                                    openDayPopup={openDayPopup}
                                    openLeaveModal={openLeaveModal}
                                    startOfWeek={startOfWeek}
                                    showLocation={showLocation}
                                />
                            );
                        })}
                    <WeekResume
                        practitionerSchedules={practitionerSchedules}
                        practitionerLeaves={practitionerLeaves}
                        startOfWeek={startOfWeek}
                    />
                </tbody>
            </table>
        </>
    );
}

WeekView.propTypes = {
    classes: PropTypes.object.isRequired,
    practitionerSchedules: PropTypes.array.isRequired,
    practitionerFilters: PropTypes.array.isRequired,
    filterByInactives: PropTypes.bool.isRequired,
    setFilterByInactives: PropTypes.func.isRequired,
    practitionerLeaves: PropTypes.array.isRequired,
    setDayPopupData: PropTypes.func.isRequired,
    setLeaveData: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired
};

export default withStyles(style)(WeekView);
