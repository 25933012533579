import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import Classnames from 'classnames';
import Moment from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import PropTypes from 'prop-types';
import PractitionerCard from '../common/practitionerCard';
import WeekDayCalendar from './weekDayCalendar';

import { viewPractitionerWeekScheduleStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import { getLeaves, getShifts } from '../../services/resumedDataHelpers';
const style = mergeStyles(commonStyle, viewPractitionerWeekScheduleStyle);

MomentDurationFormat(Moment);

function PractitionerWeekSchedule({
    classes,
    schedules,
    leaves,
    practitioner,
    practitionerIndex,
    openDayPopup,
    openLeaveModal,
    startOfWeek,
    showLocation
}) {
    const week = Array(7)
        .fill(startOfWeek.clone())
        .map((e, i) => e.clone().add(i, 'days'));

    const title = dayIndex => {
        if (practitionerIndex) {
            return '';
        }
        return `${startOfWeek
            .clone()
            .add(dayIndex, 'days')
            .format('ddd DD/MM/YY')}`;
    };

    const weekTotalHours = schedules
        .reduce(
            (duration, schedule) =>
                duration
                    .clone()
                    .add(Moment(schedule.end, 'HH:mm').diff(Moment(schedule.start, 'HH:mm'), 'minutes'), 'minutes'),
            Moment.duration(0)
        )
        .format('HH:mm', { trim: false });

    const renderLeaves = day => {
        return getLeaves(leaves, day).map((leaveTime, index) => {
            return (
                <Typography className={classes.textAlignCenter} key={index}>
                    {leaveTime}
                </Typography>
            );
        });
    };

    const renderShifts = day => {
        return (
            <Typography className={classes.textAlignCenter}>{getShifts(schedules, day, leaves)}</Typography>
        );
    };

    return (
        <tr className={Classnames(classes.week, classes.practitionerWeekSchedule)}>
            <PractitionerCard practitioner={practitioner} shifts={schedules.length} totalHours={weekTotalHours} renderLeaves={renderLeaves} renderShifts={renderShifts} />
            {week.map((day, dayIndex) => (
                <WeekDayCalendar
                    day={day}
                    practitionerIndex={practitionerIndex}
                    schedule={schedules.filter(schedule => day.isSame(Moment(schedule.date), 'day'))}
                    leave={leaves.filter(schedule => day.isSame(Moment(schedule.date), 'day'))}
                    dayIndex={dayIndex}
                    title={title(dayIndex)}
                    openDayPopup={openDayPopup}
                    openLeaveModal={openLeaveModal}
                    key={dayIndex}
                    practitioner={practitioner}
                    showLocation={showLocation}
                />
            ))}
        </tr>
    );
}

PractitionerWeekSchedule.propTypes = {
    classes: PropTypes.object.isRequired,
    schedules: PropTypes.array.isRequired,
    leaves: PropTypes.array.isRequired,
    practitioner: PropTypes.object.isRequired,
    practitionerIndex: PropTypes.number.isRequired,
    openDayPopup: PropTypes.func.isRequired,
    openLeaveModal: PropTypes.func.isRequired,
    startOfWeek: PropTypes.object.isRequired,
    showLocation: PropTypes.bool.isRequired
};

export default withStyles(style)(PractitionerWeekSchedule);
