import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, withStyles, Button, Typography, IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import Item from './templateItem';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';

import { deleteTemplate } from '../../actions/scheduleActions';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';

const style = mergeStyles(commonStyle, sharedStyle);

const ManageTemplateModal = ({ classes, manageTemplateData, setManageTemplateData }) => {
    const dispatch = useDispatch();
    // Contains all he templates
    const templates = useSelector(state => state.schedule.templates);

    // Delete confirmation modal
    const [showConfirm, setShowConfirm] = useState(false);

    // Selected Templates the user wants do remove
    const [selectedTemplates, setSelectedTemplates] = useState([]);

    const handleClose = () => {
        setManageTemplateData(false);
        // Clear selected templates array when the modal is closed
        setSelectedTemplates([]);
    };

    const handleSelectItem = item => {
        // If the item received is already in the selected array, remove it
        selectedTemplates.includes(item)
            ? setSelectedTemplates(selectedTemplates.filter(_t => _t !== item))
            : setSelectedTemplates([...selectedTemplates, item]);
    };

    /*useEffect(() => {
        dispatch(getTemplates());
        // eslint-disable-next-line
    }, []);*/

    const renderTemplates = () =>
        templates.map(template => (
            <Item key={template.id} id={template.id} name={template.name} itemSelected={handleSelectItem} />
        ));

    const handleUpdate = () => {
        dispatch(deleteTemplate(selectedTemplates, handleClose()));
    };

    return (
        <>
            <Modal open={manageTemplateData} onClose={handleClose} className={classes.modalContainer}>
                <Paper className={classNames(classes.modalPaper, classes.manageTemplateModal)}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            placeContent: 'space-between'
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '1.2rem',
                                marginLeft: 90,
                                fontWeight: 500
                            }}
                        >
                            Delete template
                        </Typography>
                        <IconButton onClick={handleClose} style={{ float: 'right', padding: 8 }}>
                            <Clear style={{ color: 'black' }} />
                        </IconButton>
                    </div>
                    {templates.length ? renderTemplates() : 'No templates added'}
                    <div
                        className={classNames(
                            classes.row,
                            classes.justifyCenter,
                            classes.itemSpacingContainer,
                            classes.buttonSpacingContainer
                        )}
                    >
                        <Button
                            className={classNames(classes.button, classes.cancelButton)}
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={classNames(classes.button, classes.confirmButton, classes.itemSpacing)}
                            variant="contained"
                            onClick={() => {
                                if (selectedTemplates.length) {
                                    setShowConfirm(true);
                                } else {
                                    handleClose();
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Paper>
            </Modal>
            {showConfirm && (
                <CancelContinueModal
                    setOpen={setShowConfirm}
                    onContinue={handleUpdate}
                    title="Delete"
                    continueButtonText="Delete"
                    contentText={`Are you sure you want to delete ${
                        selectedTemplates.length > 1 ? 'these templates' : 'this template'
                    }?`}
                />
            )}
        </>
    );
};

ManageTemplateModal.propTypes = {
    classes: PropTypes.object.isRequired,
    manageTemplateData: PropTypes.bool.isRequired,
    setManageTemplateData: PropTypes.func.isRequired
};

export default withStyles(style)(ManageTemplateModal);
