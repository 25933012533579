import React, { useEffect, useState, useRef } from 'react';
import { withStyles, Typography, Button, IconButton } from '@material-ui/core';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Moment from 'moment';
import moment from 'moment-timezone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ChevronBack, ChevronForward } from '../../assets/icons';
import EditPractitionerWeekSchedule from './editPractitionerWeekSchedule';
import EditScheduleButton from './editScheduleButton';
import practitionerScheduleApi from '../../api/practitionerScheduleApi';
import CalendarHeader from '../common/calendarHeader';
import NavigationCalendarButton from '../common/navigationCalendarButton';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
import { getQueryParams } from '../../services/locationHelper';
import { getClinicsAction } from '../../actions/clinicActions';
import { sharedStyle } from './style';
import { commonStyle, mergeStyles } from '../../style/common';
import { useDispatch, useSelector } from 'react-redux';
import { CURRENT_CLINIC } from '../../collums-constants/storageKeys';
import CancelContinueModal from '../../collums-components/components/common/CancelContinueModal';
// Not in use anymore since #4421
// import ClinicAvailability from '../../collums-components/components/common/ShowClinicAvailability';
const style = mergeStyles(commonStyle, sharedStyle);

function EditSchedule({ classes }) {
    const location = useLocation();
    const history = useHistory();
    const [currentClinicName, setCurrentClinicName] = useState();
    const [form, setForm] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const initialForm = useRef();
    const hasFormDataChanged = useRef(false);
    const nextDateChange = useRef(null);

    const [isFormChangeWarningOpen, setIsFormChangeWarningOpen] = useState(false);

    const currentClinicId = localStorage.getItem(CURRENT_CLINIC);
    const [schedulesAnotherLoc, setSchedulesAnotherLoc] = useState({});

    let clinics = useSelector(store => store.clinic.clinics);

    useEffect(() => {
        if (clinics?.length) {
            const findClinic = clinics.find(item => item.id === currentClinicId);
            setCurrentClinicName(findClinic ? findClinic.accountName : '');
        }
    }, [clinics, currentClinicId]);

    const refreshForm = async () => {
        setIsLoading(true);
        const params = getQueryParams(location);
        const selectedDate = Moment(params.date, 'YYYY-MM-DD');
        const dayFrom = selectedDate.clone().startOf('isoWeek');
        const dayTo = selectedDate.clone().endOf('isoWeek');

        const query = {
            dayFrom: dayFrom.format('YYYY-MM-DD'),
            dayTo: dayTo.format('YYYY-MM-DD'),
            location: [currentClinicId]
        };

        const result = await practitionerScheduleApi.query(query);
        const schedulesFromAnotherLocations = await practitionerScheduleApi.schedulesFromAnotherLocations(query);
        if (schedulesFromAnotherLocations?.length) {
            const practitionerSchedules = {};
            schedulesFromAnotherLocations.map(schedule => {
                if (!practitionerSchedules?.[schedule.practitioner]) {
                    practitionerSchedules[schedule.practitioner] = [];
                }
                practitionerSchedules[schedule.practitioner].push({
                    ...schedule,
                    date: Moment(schedule.date).format('YYYY-MM-DD')
                });
                return false;
            });
            setSchedulesAnotherLoc(practitionerSchedules);
        }
        const newForm = result.map(item => ({
            practitioner: item.practitioner,
            week: _.times(7, index => {
                const schedule = item.schedules.find(
                    schedule =>
                        Moment(schedule.date).format('YYYY-MM-DD') ===
                        dayFrom
                            .clone()
                            .add(index, 'days')
                            .format('YYYY-MM-DD')
                );
                if (schedule) {
                    return {
                        working: true,
                        id: schedule.id,
                        clinic: schedule.clinic.id,
                        start: schedule.start,
                        end: schedule.end,
                        appointmentsCount: schedule.appointmentsCount >= 1 ? true : false
                    };
                } else {
                    return {
                        working: false,
                        start: '09:00',
                        end: '17:00'
                    };
                }
            }),
            leaves: _.times(7, index => {
                const leaves = item.leaves.filter(
                    leave =>
                        Moment(leave.date).format('YYYY-MM-DD') ===
                        dayFrom
                            .clone()
                            .add(index, 'days')
                            .format('YYYY-MM-DD')
                );
                return leaves;
            }),
            jobRoles: ['Doctor'],
            tags: []
        }));

        hasFormDataChanged.current = false;
        initialForm.current = newForm;

        setForm(newForm);
        setIsLoading(false);
        setIsFormChangeWarningOpen(false);
    };

    const setPractitionerForm = (practitionerIndex, newWeek = null, newLeave = null) => {
        const checkFormChanged = form => {
            if (form.length !== initialForm.current.length) {
                return true;
            }

            // result defines has any property been changed.
            let result = false;

            const current = initialForm.current;
            form.every((el, index) => {
                if (!current[index]) {
                    result = true;
                    return false;
                }

                if ((el?.week || []).length !== (current[index]?.week || []).length) {
                    result = true;
                    return false;
                }

                if ((el?.leaves || []).length !== (current[index]?.leaves || []).length) {
                    result = true;
                    return false;
                }

                const weekCheck = el.week.find((weekElement, weekIndex) => {
                    if (!current[index]?.week[weekIndex]) {
                        return true;
                    }

                    return !_.isEqual(weekElement, current[index].week[weekIndex]);
                });

                if (weekCheck) {
                    result = true;
                    return false;
                }

                const leavesCheck = el.leaves.find((leaveElement, leaveIndex) => {
                    if (!current[index]?.leaves[leaveIndex]) {
                        return true;
                    }

                    return !_.isEqual(leaveElement, current[index].leaves[leaveIndex]);
                });

                if (leavesCheck) {
                    result = true;
                    return false;
                }

                return true;
            });

            return result;
        };

        const newPractitionerForm = { ...form[practitionerIndex] };
        if (newWeek) {
            newPractitionerForm.week = [...newWeek];
        }
        if (newLeave) {
            newPractitionerForm.leaves = [...newLeave];
        }
        const newForm = [...form];
        newForm.splice(practitionerIndex, 1, newPractitionerForm);
        setForm(newForm);

        hasFormDataChanged.current = checkFormChanged(newForm);
    };

    if (location.search === '') {
        history.push({ pathname: '/schedule/edit', search: `?date=${moment().format('YYYY-MM-DD')}` });
    }

    const handleDateChange = date => {
        if (hasFormDataChanged.current) {
            nextDateChange.current = moment(date).format('YYYY-MM-DD');
            setIsFormChangeWarningOpen(true);
            setIsLoading(false);
        } else {
            nextDateChange.current = null;
            setIsFormChangeWarningOpen(false);
            history.push(`/schedule/edit?date=${moment(date).format('YYYY-MM-DD')}`);
        }
    };

    const goToViewSchedule = () => {
        history.push('/schedule');
    };

    const params = new URLSearchParams(location.search);
    const date = params.get('date');
    const startOfWeek = moment.utc(date, 'YYYY-MM-DD').startOf('isoWeek');
    const endOfWeek = startOfWeek.clone().endOf('isoWeek');
    const previousWeek = startOfWeek
        .clone()
        .subtract(1, 'week')
        .format('YYYY-MM-DD');
    const nextWeek = startOfWeek
        .clone()
        .add(1, 'week')
        .format('YYYY-MM-DD');
    const startOfWeekString = startOfWeek.format('DD/MM/YYYY');
    const endOfWeekString = endOfWeek.format('DD/MM/YYYY');

    const week = Array(7)
        .fill(startOfWeek.clone())
        .map((e, i) => e.clone().add(i, 'days'));
    const cells = [
        { content: '', style: { width: 164 } },
        ...week.map(e => ({ content: e.format('ddd DD/MM/YY'), style: { textAlign: 'center' } }))
    ];

    useEffect(() => {
        dispatch(getClinicsAction());
    }, [dispatch]);

    useEffect(() => {
        refreshForm();
        // eslint-disable-next-line
    }, [location]);

    // Not in use anymore since #4421
    // const [showClinicTime, setShowClinicTime] = useState(false);

    const onChangeNavigationDate = (e, date) => {
        e.preventDefault();

        if (hasFormDataChanged.current) {
            nextDateChange.current = date;
            setIsFormChangeWarningOpen(true);
        } else {
            nextDateChange.current = null;
            setIsFormChangeWarningOpen(false);
            history.push(`/schedule/edit?date=${date}`);
        }
    };

    return (
        <>
            {isLoading && <LoadingScreen />}
            <div className={classes.container}>
                <Typography className={classes.title} variant="h4">
                    {currentClinicName ? `${currentClinicName} - ` : ''}Edit Staff Schedules
                </Typography>
                {/* Week navigation */}
                <div
                    className={classNames(
                        classes.row,
                        classes.itemSpacingContainer,
                        classes.alignCenter,
                        classes.justifyCenter
                    )}
                >
                    <Link onClick={e => onChangeNavigationDate(e, previousWeek)}>
                        <IconButton>
                            <ChevronBack />
                        </IconButton>
                    </Link>
                    <Typography>{`${startOfWeekString} - ${endOfWeekString}`}</Typography>
                    <Link onClick={e => onChangeNavigationDate(e, nextWeek)}>
                        <IconButton>
                            <ChevronForward />
                        </IconButton>
                    </Link>
                    <NavigationCalendarButton
                        onChange={value => {
                            setIsLoading(true);
                            handleDateChange(value);
                        }}
                        value={startOfWeek}
                    />
                </div>
                <table className={classNames(classes.calendarTable)}>
                    <CalendarHeader cells={cells} />
                    <tbody>
                        {form.map((practitionerForm, practitionerIndex) => {
                            return (
                                <EditPractitionerWeekSchedule
                                    key={date + practitionerIndex}
                                    practitionerForm={practitionerForm}
                                    setPractitionerForm={setPractitionerForm}
                                    practitionerIndex={practitionerIndex}
                                    startOfWeek={startOfWeek}
                                    clinics={clinics}
                                    schedulesAnotherLoc={schedulesAnotherLoc?.[practitionerForm.practitioner.id] ?? []}
                                    currentClinicId={currentClinicId}
                                />
                            );
                        })}
                    </tbody>
                </table>
                {/* Cancel and save buttons */}
                <div className={classNames(classes.row, classes.itemSpacingContainer, classes.topSpacing)}>
                    <Button
                        onClick={goToViewSchedule}
                        className={classNames(classes.button, classes.cancelButton)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <EditScheduleButton
                        form={form}
                        startOfWeek={startOfWeek}
                        initialForm={initialForm}
                        onSuccess={refreshForm}
                        currentClinicId={currentClinicId}
                    />
                    {/*showClinicTime && <ClinicAvailability onClose={() => setShowClinicTime(false)} />*/}
                </div>
            </div>
            <CancelContinueModal
                title="Data loss warning"
                open={isFormChangeWarningOpen}
                setOpen={setIsFormChangeWarningOpen}
                contentText={'Please go back and save your changes before moving on to avoid losing your changes'}
                cancelButtonText="Back"
                continueButtonText="Continue WITHOUT saving"
                onCancel={() => {
                    setIsFormChangeWarningOpen(false);
                }}
                onContinue={() => {
                    setIsFormChangeWarningOpen(false);

                    if (nextDateChange.current) {
                        history.push(`/schedule/edit?date=${nextDateChange.current}`);
                    }
                }}
            />
        </>
    );
}

EditSchedule.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(EditSchedule);
