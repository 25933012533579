import api from './api';

const login = ({ email, password }) =>
    api.request({
        method: 'POST',
        url: '/login',
        data: { email, password }
    });

const getMe = () =>
    api.request({
        method: 'GET',
        url: '/user/me'
    });

const lockUser = () =>
    api.request({
        method: 'POST',
        url: '/lock-user'
    });

const isUserLocked = () =>
    api.request({
        method: 'GET',
        url: '/is-user-locked'
    });

export default {
    login,
    getMe,
    lockUser,
    isUserLocked
};
