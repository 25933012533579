import * as actions from './actionTypes';

import { getClinic } from '../api/clinicApi';

export const getClinicsAction = () => {
    return dispatch => {
        getClinic().then(result =>
            dispatch({
                type: actions.FETCHED_CLINICS,
                payload: result
            })
        );
    };
};
