import api from './api';

const query = params => {
    return api.request({
        method: 'GET',
        url: '/practitioner-schedules',
        params: { ...params, isFromSchedule: true }
    });
};
const schedulesFromAnotherLocations = params => {
    return api.request({
        method: 'GET',
        url: '/practitioner-schedules-another-location',
        params
    });
};
const create = data =>
    api.request({
        method: 'POST',
        url: '/practitioner-schedules',
        data
    });

const update = data =>
    api.request({
        method: 'PUT',
        url: '/practitioner-schedules',
        data
    });

export default {
    query,
    create,
    update,
    schedulesFromAnotherLocations
};
