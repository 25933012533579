import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Chip, TextField, Typography, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import { getPractitioners } from '../../actions/practitionerActions';

import { commonStyle } from '../../style/common';
const style = commonStyle;

// eslint-disable-next-line no-unused-vars
function SelectPractitionerInput({ onSelect, classes, multiple, className, value, fromLeaveModal }) {
    const dispatch = useDispatch();
    const practitioners = useSelector(state => state.practitioner.practitioners);
    useEffect(() => {
        dispatch(getPractitioners());
    }, [dispatch]);

    const selectPractitioner = async practitioners => {
        onSelect(practitioners);
    };

    if (fromLeaveModal) {
        return (
            <Autocomplete
                className={className}
                fullWidth
                autoComplete
                multiple={multiple}
                filterSelectedOptions={multiple}
                disableCloseOnSelect={multiple}
                options={practitioners}
                value={value}
                onChange={(event, value) => selectPractitioner(value)}
                getOptionLabel={option => option.displayName}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            style: { marginTop: -7 }
                        }}
                    />
                )}
            />
        );
    }

    return (
        <Autocomplete
            className={className}
            fullWidth
            autoComplete
            autoHighlight
            limitTags={1}
            multiple={multiple}
            filterSelectedOptions={multiple}
            disableCloseOnSelect={multiple}
            options={practitioners}
            value={value}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        key={option.id}
                        label={<Typography style={{ fontSize: 14 }}>{option.displayName}</Typography>}
                        {...getTagProps({ index })}
                    />
                ))
            }
            onChange={(event, value) => selectPractitioner(value)}
            getOptionLabel={option => option.displayName}
            renderInput={params => <TextField {...params} variant="outlined" label="Practitioner" />}
        />
    );
}

SelectPractitionerInput.propTypes = {
    onSelect: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.any,
    classes: PropTypes.object.isRequired,
    fromLeaveModal: PropTypes.bool
};

export default withStyles(style)(SelectPractitionerInput);
