import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, withStyles } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash';

import { createPratictionerSchedule } from '../../actions/scheduleActions';

import { commonStyle, mergeStyles } from '../../style/common';
import { sharedStyle } from './style';
import { useHistory } from 'react-router-dom';
import practitionerScheduleApi from '../../api/practitionerScheduleApi';

const style = mergeStyles(commonStyle, sharedStyle);

const CreateScheduleButton = ({ form, classes, setOverlapModalContent, setModalContent, setModalContentAction, setIsLoading }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const clinics = useSelector(store => store.clinic.clinics);
    const dispatch = useDispatch();
    const history = useHistory();

    const getEndDate = () => {
        if (!form.recurring) {
            return Moment(form.startDate, 'YYYY-MM-DD')
                .add(form.weeks.length * 7 - 1, 'days')
                .format('YYYY-MM-DD');
        } else if (form.endType === 'after') {
            return Moment(form.startDate, 'YYYY-MM-DD')
                .add(form.weeks.length * 7 * form.endAfter - 1, 'days')
                .format('YYYY-MM-DD');
        } else {
            return form.endDate;
        }
    };

    // WIP test if working days is in the date period selected
    const isInWorkingDays = () => {
        const startDate = Moment(form.startDate, 'YYYY-MM-DD').startOf('day');
        const endDate = Moment(getEndDate(), 'YYYY-MM-DD').startOf('day');
        const startOfWeek = startDate.clone().startOf('isoWeek');

        const daysToRemoveAtStart = startDate.diff(startOfWeek, 'days');
        const amountOfOccurences = Math.ceil(endDate.diff(startOfWeek, 'weeks', true) / form.weeks.length);
        const daysToRemoveAtEnd = startOfWeek
            .clone()
            .add(form.weeks.length * amountOfOccurences * 7 - 1, 'days')
            .diff(endDate, 'days');
        let days = _.flattenDepth(
            _.times(amountOfOccurences, () => form.weeks),
            2
        );
        days = days.slice(daysToRemoveAtStart, days.length - daysToRemoveAtEnd);
        return days.some(day => day.working);
    };

    const checkPractitionersLocation = form => {
        const clinicsIndex = {};
        clinics.map(clinic => {
            clinicsIndex[clinic.id] = clinic.accountName;
            return false;
        });

        let weekClinics = form.weeks.map(week => week.map(day => day.clinic).filter(clinic => !!clinic));
        weekClinics = [...new Set(weekClinics.flatMap(clinic => clinic))];

        let result = form.practitioners.map(practitioner => {
            const practitionerClinics = practitioner.locations.map(location => location.clinic);

            return weekClinics.map(pClinic =>
                !practitionerClinics.includes(pClinic)
                    ? { practitioner: practitioner.displayName, clinic: pClinic, clinicName: clinicsIndex?.[pClinic] }
                    : false
            );
        });

        result = result.flatMap(el => el).filter(el => el);

        return result;
    };

    const saveSchedule = async (checkConflicts = true) => {
        const wrongLocations = checkPractitionersLocation(form);
        if (wrongLocations && wrongLocations.length) {
            const message = [...wrongLocations.map(item => `${item.practitioner} to ${item.clinicName}`)];
            toastr.warning('', 'Wrong clinic to practitioner: \n\n' + message.join(', '), {
                className: classes.toastrWrapper
            });
            return true;
        }

        const startDate = Moment(form.startDate, 'YYYY-MM-DD').startOf('day');
        const endDate = Moment(form.endDate, 'YYYY-MM-DD').startOf('day');
        const workingDays = form.weeks.flatMap(week => week.filter(day => day.working));
        if (!form.practitioners.length) {
            toastr.warning('Select employees for this schedule');
        } else if (!workingDays.length) {
            toastr.warning('Schedule must have at least one working day');
        } else if (!workingDays.every(day => day.clinic)) {
            toastr.warning('Clinic must be defined');
        } else if (!workingDays.every(day => day.start)) {
            toastr.warning('Start time must be defined');
        } else if (!workingDays.every(day => day.end)) {
            toastr.warning('End time must be defined');
        } else if (!workingDays.every(day => Moment(day.end, 'HH:mm').isAfter(Moment(day.start, 'HH:mm')))) {
            toastr.warning('End time must be after Start time');
        } else if (!form.startDate) {
            toastr.warning('Start date must be defined');
        } else if (form.recurring && form.endType === 'by' && !form.endDate) {
            toastr.warning('End date must be defined');
        } else if (form.recurring && form.endType === 'after' && !form.endAfter) {
            toastr.warning('End after must be defined');
        } else if (form.recurring && form.endType === 'by' && startDate.isAfter(endDate)) {
            toastr.warning('End date must be after Start date');
        } else if (!isInWorkingDays(startDate)) {
            toastr.warning('Must have at least 1 working day in the selected date period');
        } else {
            const values = {
                startDate: form.startDate,
                practitioners: form.practitioners.map(item => item.id),
                endDate: getEndDate(),
                weeks: form.weeks.map(week => week.map(day => (day.working ? day : { working: false })))
            };


            if (checkConflicts) {
                let dayTo = getEndDate();
                const startDay = (new Date(form.startDate)).getDay();
                let startDayOfTheWeek = startDay === 0 ? 6 : startDay - 1;
                const query = {
                    dayFrom: form.startDate,
                    dayTo: dayTo
                };
                const schedulesFromAnotherLocations = await practitionerScheduleApi.schedulesFromAnotherLocations(query);

                let allOtherClinicsConflicts = [];
                let allSameClinicConflicts = [];
                let i = 1;
                for (let day = form.startDate; day <= dayTo;) {
                    let weekIndex = (Math.ceil((i+startDayOfTheWeek)/7) - 1) % form.weeks.length;

                    const dayIndex = (i - 1 + startDayOfTheWeek) % 7;
                    const checkingDay = form.weeks[weekIndex][dayIndex];
                    const otherClinicsConflicts = form.practitioners.map(pract => {
                        const conflictedSchedules = schedulesFromAnotherLocations.filter(schedule => {
                            if (schedule.date.slice(0, 10) !== day) {
                                return false;
                            }
                            if (pract.id !== schedule.practitioner) {
                                return false;
                            }
                            if (checkingDay.clinic && checkingDay.clinic !== schedule.clinic[0].id) {
                                if (checkingDay.start < schedule.end && checkingDay.start > schedule.start) {
                                    return true;
                                }
                                if (checkingDay.end < schedule.end && checkingDay.end > schedule.start) {
                                    return true;
                                }
                            }
                            return false;
                        });
                        if (conflictedSchedules.length) {
                            return `Practitioner ${pract.displayName} already working at ${clinics.find(clinic => clinic.id === conflictedSchedules[0].clinic[0].id)?.accountName} at ${day}`;
                        }
                        return null;
                    });
                    const sameClinicConflicts = form.practitioners.map(pract => {
                        const conflictedSchedules = schedulesFromAnotherLocations.filter(schedule => {
                            if (schedule.date.slice(0, 10) !== day) {
                                return false;
                            }
                            if (pract.id !== schedule.practitioner) {
                                return false;
                            }
                            if (!schedule.working) {
                                return true;
                            }
                            if (checkingDay.clinic === schedule.clinic[0].id) {
                                return true;
                            }
                            return false;
                        });
                        if (conflictedSchedules.length) {
                            return `Practitioner ${pract.displayName} already working at ${clinics.find(clinic => clinic.id === conflictedSchedules[0].clinic[0].id)?.accountName} at ${day}`;
                        }
                        return null;
                    });
                    allOtherClinicsConflicts.push(...otherClinicsConflicts);
                    allSameClinicConflicts.push(...sameClinicConflicts);

                    day = Moment(day).add(1, 'days').format('YYYY-MM-DD');
                    i++;
                }
                allOtherClinicsConflicts = allOtherClinicsConflicts.filter(conflict => !!conflict);
                allSameClinicConflicts = allSameClinicConflicts.filter(conflict => !!conflict);
                if (allOtherClinicsConflicts.length) {
                    setOverlapModalContent(allOtherClinicsConflicts);
                    return true;
                }
                if (allSameClinicConflicts.length) {
                    setModalContentAction({ func: saveSchedule });
                    setModalContent(allSameClinicConflicts);
                    return true;
                }
            }

            const onSuccess = () => {
                toastr.success('Schedule successfully created');
                setIsDisabled(false);
                setIsLoading(false);
                history.push('/schedule');
            };

            setIsDisabled(true);
            setIsLoading(true);
            dispatch(createPratictionerSchedule(values, onSuccess));
        }
    };

    return (
        <Button
            className={classNames(classes.button, classes.saveButton)}
            variant="contained"
            onClick={saveSchedule}
            disabled={isDisabled}
        >
            Save
        </Button>
    );
};

CreateScheduleButton.propTypes = {
    form: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    setOverlapModalContent: PropTypes.func,
    setModalContent: PropTypes.func,
    setModalContentAction: PropTypes.func,
    setIsLoading: PropTypes.func
};

export default withStyles(style)(CreateScheduleButton);
