import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import utilsReducer from './utilsReducer';
import practitionerReducer from './practitionerReducer';
import scheduleReducer from './scheduleReducer';
import clinicReducer from './clinicReducer';

export default combineReducers({
    utils: utilsReducer,
    practitioner: practitionerReducer,
    schedule: scheduleReducer,
    toastr: toastrReducer,
    clinic: clinicReducer
});
